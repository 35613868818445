import _ from "lodash";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Checkbox,
  FormGroup,
  TextField,
  TextareaAutosize,
  Input,
} from "@material-ui/core";
import { Session_Reducer_PushTransactionID } from "actions/SessionActions";
import { SettingsActions } from "components/shared/CardActions";
import { ErrorMessages } from "components/shared/ErrorMessages";
import {
  ApplyIndicator,
  CreateSettingsButton,
  ISettingsComponentProps,
  OverriddenIndicator,
} from "components/shared/SettingPagesVarious";
import { HelpTextButton } from "components/shared/various";
import { WebLevelLabel } from "components/shared/WebLevelLabel";
import { useGeneralDisplaySettingsClient } from "hooks/useHttpClient";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TransactionIDInfo } from "types/SessionTypes";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  AdminEntityValidationResult,
  CreateSettingsRequest,
  DisplaySettingAccess,
  DisplaySideBarSettingAccess,
  OtherSettingsAsKeyValue,
  SettingsOrder,
  ShowFareHeader,
  SmartCalenderAccess,
  StartPage,
  UpdateGeneralDisplaySettingsModel,
  SmartCalender,
  PublicServicesClient,
  ApiCredentialsOptions,
  cabinClasses,
  CabinClass,
  DefaultPassengerGender,
  DefaultPassengerDOB,
  ShowHotelTab,
  ShowCabinContainer,
  B2CSettings,
  ShowHotelTabB2C,
  AirPortSearchType,
  ShowExtraAncillariesTabB2C,
  ShowLanguageDecimalTabB2C,
  ShowDateInput,
  ShowDefaultFilter,
  ShowBackgroundModalColor,
  ShowDefaultBrandName,
  ShowDefaultAssistance,
  showPriceOption,
  DOBNameField,
  AllowedLanguageCode,
  LanguageCode,
  ShowDefaultFilterPosition,
  ShowFareCollapse,
  FareShowValue,
  ShowDefaultUpsell,
  ShowDefaultUpsellB2B,
  AgentPlusBookingManagerColumns,
  ShowDefaultFareHeaderB2C,

} from "WebApiClient";
import * as HelpTexts from "./displaySettings.HelpTexts";
import { Multiselect } from "react-widgets";
import { AgentApiURL } from "Constants";
import { useLocation } from "react-router-dom";

const GeneralDisplaySettings: React.FC<ISettingsComponentProps> = (props) => {
  const { ReadOnly, WebLevel, Id, LevelName } = props;
  const client = useGeneralDisplaySettingsClient();
  const dispatch = useDispatch();
  const [Settings, setSettings] = useState<UpdateGeneralDisplaySettingsModel>();
  const [loading, setLoading] = useState(true);
  const [validationState, setValidationState] = useState<AdminEntityValidationResult | undefined>(undefined);
  const [serverError, setServerError] = useState(false);
  const [OtherSettingsKeyValue, setOtherSettingsKeyValue] = useState<OtherSettingsAsKeyValue | any>(undefined);

  // Initial filter B2B state with 'order' field
  const initialFilterB2B = [
    { label: "Stops", checked: true, order: 1 },
    { label: "Baggage included", checked: true, order: 2 },
    { label: "Airports", checked: true, order: 3 },
    { label: "Airlines", checked: true, order: 4 },
    { label: "Alliances", checked: true, order: 5 },
    { label: "Departure time", checked: true, order: 6 },
    { label: "Arrival time", checked: true, order: 7 },
    { label: "Cabin class", checked: true, order: 8 },
    { label: "Fare type", checked: true, order: 9 },
    { label: "QueryPCC", checked: true, order: 10 },
    { label: "BookingPCC", checked: true, order: 11 },
    { label: "Source", checked: true, order: 12 },
    { label: "Allotments", checked: true, order: 13 },
    { label: "Included services", checked: true, order: 14 },
  ];
  const [filtersB2B, setFilterB2B] = useState(initialFilterB2B);
  const [filtersBoolB2B, setFilterBoolB2B] = useState(false);

  // Initial filter B2C state with 'order' field
  const initialFilterB2C = [
    { label: "Departure time", checked: true, order: 1 },
    { label: "Arrival time", checked: true, order: 2 },
    { label: "Stops", checked: true, order: 3 },
    { label: "Baggage included", checked: true, order: 4 },
    { label: "Baggage type", checked: true, order: 5 },
    { label: "Airports", checked: true, order: 6 },
    { label: "Cabin class", checked: true, order: 7 },
    { label: "Allotments", checked: true, order: 8 },
    { label: "Included services", checked: true, order: 9 },
    { label: "Alliances", checked: true, order: 10 },
    { label: "Airlines", checked: true, order: 11 },
  ];
  const [filtersB2C, setFilterB2C] = useState(initialFilterB2C);
  const [filtersBoolB2C, setFilterBoolB2C] = useState(false);

  const [B2cSettings, setB2cSettings] = useState<B2CSettings>({
    HotelTab: "All",
    AirPortSearchType: "All",
    ShowExtraAncillariesTabB2C: "On",
    ShowLanguageDecimalTabB2C: "English",
    ShowDateInput: "Left",
    ShowDefaultFilter: "On",
    CabinClass: CabinClass,
    TermsConditionsLink: "",
    ShowBackgroundModalColor: "On",
    showMarqueeText: "",
    showMarqueeFrom: "",
    showMarqueeTo: "",
    showBrandName: "On",
    showPassengerTitleName: "On",
    showFilterPosition: "Right",
    showAssistance: "On",
    showUpsell: "On",
    showUpsellB2B: "On",
    showFareHeaderB2C: "On",
    Style: "",
    ConfirmationText: "<p>Thank you for booking.#Booking Reference# You will receive a confirmation email soon.</p>",
    priceOption: "",
    AllowedLanguage: AllowedLanguageCode,
    RouteFilter: "",
    filtersOrderB2B: filtersB2B,
    filtersOrderB2C: filtersB2C,
    fareShowB2B: ShowFareCollapse,
    allowedBookingManagerCouponColumns:[1, 4, 512, 256, 1024, 2048, 4096, 8192, 16384, 32768, 32, 64, 16, 8],
  });
  const [smartCalender, setSmartCalender] = useState<SmartCalender | any>();
  const [flightDate, setFlightDate] = useState(true);
  const [isSetting, setIsSetting] = useState(true);
  const [allotmentDate, setAllotmentDate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<any[]>();
  const [All, setAll] = useState(false);
  const [cabinsSelect, setCabinsSelect] = React.useState(CabinClass);
  const [All2, setAll2] = useState(false);
  const [bgColor, setBgColor] = React.useState("#242424");
  const [color, setColor] = React.useState("#ffffff");

  useEffect(() => {
    GetSettings(WebLevel, Id);
  }, [WebLevel, Id]);

  // manage othersettingstext
  function addOtherSettings() {
    if (
      smartCalender?.showSmartCalender !== undefined ||
      smartCalender?.airline != undefined ||
      OtherSettingsKeyValue?.showSmartCalender !== undefined ||
      Settings?.otherSettingsAsKeyValueText !== undefined ||
      smartCalender?.flightDates !== undefined ||
      smartCalender?.allotementDates !== undefined
    ) {
      // create string of smart calender setting
      let newString =
        smartCalender?.showSmartCalender +
        "/n" +
        JSON.stringify(smartCalender?.airline) +
        "/n" +
        smartCalender?.flightDates +
        "/n" +
        smartCalender?.allotementDates +
        "/n";

      setOtherSettingsKeyValue((prvSetting: any) => ({
        ...prvSetting,
        showSmartCalender: newString,
      }));

      let menu = JSON.stringify({ textColor: color, bgColor: bgColor });
      // create new string for othersettings combines all values in OtherSettingsKeyValue.....
      let modifiedString =
        OtherSettingsKeyValue?.menuPosition +
        "/" +
        menu +
        "\n" +
        OtherSettingsKeyValue?.fareDisplayHeader +
        "\n" +
        OtherSettingsKeyValue?.showSmartCalender +
        "\n" +
        OtherSettingsKeyValue?.fareDisplayCabin +
        "\n" +
        OtherSettingsKeyValue?.refreshTime +
        "\n" +
        JSON.stringify(OtherSettingsKeyValue?.PassengerInfo) +
        "\n" +
        OtherSettingsKeyValue?.HotelTab +
        "\n" +
        JSON.stringify(OtherSettingsKeyValue?.FieldContainer) +
        "\n" +
        OtherSettingsKeyValue?.CabinContainer;
      setSettings((prv: any) => ({
        ...prv,
        otherSettingsAsKeyValueText: modifiedString,
        b2cSettingsText: JSON.stringify(B2cSettings),
      }));
    }
  }

  useEffect(() => {
    addOtherSettings();
  }, [
    OtherSettingsKeyValue?.CabinContainer,
    OtherSettingsKeyValue?.HotelTab,
    smartCalender?.showSmartCalender,
    smartCalender?.airline,
    OtherSettingsKeyValue?.showSmartCalender,
    smartCalender?.flightDates,
    smartCalender?.allotementDates,
    OtherSettingsKeyValue?.menuPosition,
    OtherSettingsKeyValue?.fareDisplayHeader,
    OtherSettingsKeyValue?.fareDisplayCabin,
    cabinsSelect?.Business,
    cabinsSelect?.Economy,
    cabinsSelect?.First,
    cabinsSelect?.Premium,
    bgColor,
    color,
    OtherSettingsKeyValue?.refreshTime,
    OtherSettingsKeyValue?.PassengerInfo,
    OtherSettingsKeyValue?.FieldContainer,
  ]);
  // update frontendSettings
  function OnUpdate() {
    addOtherSettings();
    if (Settings) {
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      setLoading(true);
      client
        .update(Settings)
        .then((response) => {
          transactionId = response.transactionId;
          if (response.success) {
            transactionMsg = "successfully updated.";
            transactionSuccess = true;
          } else {
            transactionMsg = "could not be updated.";
          }
          if (response.serverError) {
            serverSideError = true;
          } else {
            setValidationState(response.validationResult);
          }
        })
        .catch((e) => {
          console.error(e);
          setServerError(true);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg =
              "could not be updated. A serverside error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Update General display settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `General display settings ${transactionMsg}`,
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          if (transactionSuccess) {
            Reload();
          }
          setLoading(false);
        });
    }
  }
  // reload getSetting
  function Reload() {
    GetSettings(WebLevel, Id);
  }
  // create new frontend settings
  function CreateSettings() {
    if (Id) {
      const createRequest: CreateSettingsRequest = {
        id: Id,
        sortOrder: WebLevel,
      };
      setLoading(true);
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      client.create(createRequest).then((response) => {
        transactionId = response.transactionId;
        if (response.success) {
          transactionMsg = "successfully created.";
          transactionSuccess = true;
        } else {
          transactionMsg = "could not be created.";
        }
        if (response.serverError) {
          serverSideError = true;
        }
      }).catch((e) => {
        serverSideError = true;
        console.error(e);
      }).finally(() => {
        if (serverSideError) {
          transactionMsg =
            "could not be created. A serverside error has occured";
          setServerError(true);
        }
        let transaction: TransactionIDInfo = {
          Label: `Create General display settings`,
          Success: transactionSuccess,
          Time: transactionTime,
          TransactionID: transactionId,
          Message: `General display settings ${transactionMsg}`,
        };
        dispatch(Session_Reducer_PushTransactionID(transaction));
        setLoading(false);
        if (transactionSuccess) {
          Reload();
        }
      });
    }
  }

  function GetSettings(order: SettingsOrder, Id: string | null) {
    setLoading(true);
    client.get(order, Id).then((settings: any) => {
      if (settings !== null) {
        setSettings({
          bookingManagerTab: settings.bookingManagerTab,
          dateFormat: settings.dateFormat,
          fareQueryTab: settings.fareQueryTab,
          otherSettingsAsKeyValueText: settings.otherSettingsAsKeyValueText,
          b2cSettingsText: settings.b2cSettingsText,
          id: settings.id,
          locale: settings.locale,
          startPage: settings.startPage,
        });
        if (settings?.b2cSettingsText) {
          const b2cString = JSON.parse(settings.b2cSettingsText);
          if (b2cString !== null && typeof b2cString === "object") {
            const checkCabin = b2cString.hasOwnProperty("CabinClass");
            const checkLanguage = b2cString.hasOwnProperty("AllowedLanguage");
            const checkFareShow = b2cString.hasOwnProperty("fareShowB2B");
            const checkRouteFilter = b2cString.hasOwnProperty("RouteFilter");
            if (!checkCabin) {
              b2cString.CabinClass = CabinClass;
            }
            if (!checkLanguage) {
              b2cString.AllowedLanguage = AllowedLanguageCode;
            }
            if (!checkFareShow) {
              b2cString.fareShowB2B = ShowFareCollapse;
            }
            if (!checkRouteFilter) {
              b2cString.RouteFilter = "";
            }
            if (b2cString?.filtersOrderB2B == undefined) {
              setFilterB2B(b2cString?.filtersOrderB2B)
            }
            if (b2cString?.filtersOrderB2C == undefined) {
              setFilterB2C(b2cString?.filtersOrderB2C)
            }
            if (b2cString?.showUpsell == undefined) {
              b2cString.showUpsell = "On";
            }
            if (b2cString?.showUpsellB2B == undefined) {
              b2cString.showUpsellB2B = "On";
            }
            if (b2cString?.showFareHeaderB2C == undefined) {
              b2cString.showFareHeaderB2C = "On";
            }
            if (b2cString?.TermsConditionsLink == undefined) {
              b2cString.TermsConditionsLink = "";
            }

            if (b2cString?.ShowBackgroundModalColor == undefined) {
              b2cString.ShowBackgroundModalColor = "On";
            }

            if (b2cString?.showBrandName == undefined) {
              b2cString.showBrandName = "On";
            }

            if (b2cString?.showPassengerTitleName == undefined) {
              b2cString.showPassengerTitleName = "On";
            }

            if (b2cString?.showFilterPosition == undefined) {
              b2cString.showFilterPosition = "Right";
            }

            if (b2cString?.showAssistance == undefined) {
              b2cString.showAssistance = "On";
            }

            if (b2cString?.ConfirmationText == undefined) {
              b2cString.ConfirmationText = "<p>Thank you for booking.#Booking Reference# You will receive a confirmation email soon.</p>";
            }

            if (b2cString?.filtersOrderB2B == undefined) {
              b2cString.filtersOrderB2B = filtersB2B;
            }

            if (b2cString?.filtersOrderB2C == undefined) {
              b2cString.filtersOrderB2C = filtersB2C;
            }
            if(b2cString.allowedBookingManagerCouponColumns == undefined){
              b2cString.allowedBookingManagerCouponColumns = [1, 4, 512, 256, 1024, 2048, 4096, 8192, 16384, 32768, 32, 64, 16, 8]
            }
          }
          setB2cSettings(b2cString);
        } else {
          const B2C: B2CSettings | any = {
            HotelTab: "All",
            AirPortSearchType: "All",
            ShowExtraAncillariesTabB2C: "On",
            ShowLanguageDecimalTabB2C: "English",
            ShowDateInput: "Left",
            CabinClass: CabinClass,
            showMarqueeFrom: "2024-05-20T11:54",
            showMarqueeText: "",
            showMarqueeTo: "2024-05-20T11:55",
            Style: "",
            priceOption: showPriceOption.pricePerPerson,
            AllowedLanguage: AllowedLanguageCode,
            RouteFilter: "",
            fareShowB2B: ShowFareCollapse,
            showUpsell: "On",
            showUpsellB2B: "On",
            showFareHeaderB2C: "On",
            TermsConditionsLink: "",
            ShowBackgroundModalColor: "On",
            showBrandName: "On",
            showPassengerTitleName: "On",
            showFilterPosition: "Right",
            showAssistance: "On",
            ConfirmationText: "<p>Thank you for booking.#Booking Reference# You will receive a confirmation email soon.</p>",
            filtersOrderB2B: filtersB2B,
            filtersOrderB2C: filtersB2C,
            allowedBookingManagerCouponColumns:[1, 4, 512, 256, 1024, 2048, 4096, 8192, 16384, 32768, 32, 64, 16, 8]
          };
          setB2cSettings(B2C);
        }

        let otherSetting = settings?.otherSettingsAsKeyValueText == null ||
          settings?.otherSettingsAsKeyValueText.length == 0
          ? settings?.otherSettingsAsKeyValueText ==
            "menuPosition=L/nshowFareHeader=Off"
            ? 'menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse\n{"Economy":{"Checked":true,"preSelect":false},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}\n0\n{"PassengerGender":"Unselected","PassengerDOB":"Yes"}\nshowHotelTab=None\n""\nshowCabinContainer=All'
            : 'menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse\n{"Economy":{"Checked":true,"preSelect":false},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}\n0\n{"PassengerGender":"Unselected","PassengerDOB":"Yes"}\nshowHotelTab=None\n""\nshowCabinContainer=All'
          : settings?.otherSettingsAsKeyValueText;
        // Split settingsArray and set other settings
        let settingsArray = otherSetting.split("\n");

        // Handle menuPosition and color settings
        let menuSide = "L";
        let textColor = "#ffffff";
        let bgColor = "#242424";
        if (settingsArray[0]) {
          const obj = settingsArray[0].split("/");
          menuSide = obj[0];
          if (obj.length > 1) {
            const menuOption = JSON.parse(obj[1]);
            textColor = menuOption.textColor;
            bgColor = menuOption.bgColor;
            setBgColor(bgColor);
            setColor(textColor);
          }
        }

        // Handle FieldContainer parsing
        let fieldContainer = { Container: "" };
        if (settingsArray[7]) {
          try {
            fieldContainer = JSON.parse(settingsArray[7]);
          } catch (error) {
            console.error("Error parsing JSON for FieldContainer:", error);
          }
        }

        let passengerInfo;
        // Parse settingsArray[5] if it exists and is a valid JSON string
        if (settingsArray[5]) {
          try {
            // Check if the string is valid JSON
            if (typeof settingsArray[5] === 'string' && settingsArray[5].trim().startsWith('{')) {
              const parsedInfo = JSON.parse(settingsArray[5]);
              passengerInfo = {
                PassengerGender: parsedInfo.PassengerGender || "Unselected",
                PassengerDOB: parsedInfo.PassengerDOB || "Yes",
                DOB: parsedInfo.DOB !== undefined ? parsedInfo.DOB : DOBNameField.ForPaxDobOnly,
              };
            } else {
              throw new Error('Invalid JSON string');
            }
          } catch (e) {
            console.error("Failed to parse PassengerInfo:", e);
            passengerInfo = { PassengerGender: "Unselected", PassengerDOB: "Yes", DOB: DOBNameField.ForPaxDobOnly };
          }
        } else {
          passengerInfo = { PassengerGender: "Unselected", PassengerDOB: "Yes", DOB: DOBNameField.ForPaxDobOnly };
        }
        setOtherSettingsKeyValue({
          menuPosition: menuSide,
          fareDisplayHeader: settingsArray[1] || "showFareHeader=Off",
          showSmartCalendar: settingsArray[2] || "showSmartCalender=None/n[]/nfalse/nfalse",
          fareDisplayCabin: settingsArray[3] || '{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}',
          refreshTime: settingsArray[4] || "0",
          PassengerInfo: passengerInfo,
          HotelTab: settingsArray[6] || "showHotelTab=None",
          FieldContainer: fieldContainer,
          CabinContainer: settingsArray[8] || "showCabinContainer=All",
        });

        // Set settings for cabin classes
        let cabin =
          settingsArray[3] ||
          '{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}';
        if (cabin.includes("preSelect")) {
          let newCabinData = JSON.parse(cabin);
          setCabinsSelect(newCabinData);
          if (
            Object.values(newCabinData).every((cabin: any) => cabin.Checked)
          ) {
            setAll(true);
          }
        } else {
          let newCabinData = {
            Economy: { Checked: true, preSelect: true },
            Premium: { Checked: true, preSelect: false },
            Business: { Checked: true, preSelect: false },
            First: { Checked: true, preSelect: false },
          };
          setCabinsSelect(newCabinData);
          if (Object.values(newCabinData).every((cabin) => cabin.Checked)) {
            setAll(true);
          }
        }

        // Set settings for smart calendar
        let smartCal =
          settingsArray[2] == undefined ||
            settingsArray[2] == "undefined/nundefined/nundefined/nundefined"
            ? "showSmartCalender=None/n[]/nfalse/nfalse"
            : settingsArray[2];
        let smartArray = smartCal.split("/n");
        smartArray = smartArray.includes("undefined")
          ? ["showSmartCalender=None", "[]", "false", "false", ""]
          : smartArray;
        let airlines =
          smartArray[1] == undefined ? [] : JSON.parse(smartArray[1]);
        let flightDates =
          smartArray[2] == undefined ? false : JSON.parse(smartArray[2]);
        let allotement =
          smartArray[3] == undefined ? false : JSON.parse(smartArray[3]);
        setSmartCalender({
          ...smartCalender,
          showSmartCalender:
            smartArray[0] == undefined
              ? "showSmartCalender=None"
              : smartArray[0],
          airline: airlines,
          flightDates: flightDates,
          allotementDates: allotement,
        });
        setFlightDate(flightDates);
        setAllotmentDate(allotement);
        setIsSetting(true);
        setIsSetting(true);
      } else {
        console.log("Settings are null or undefined");
        setIsSetting(false);
      }
    })
      .catch((error) => {
        console.error("Error fetching settings:", error);
        setServerError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  // delete Settings
  function DeleteSettings() {
    if (Settings) {
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      setLoading(true);
      client
        .delete(Settings?.id!)
        .then((response) => {
          if (response.success) {
            transactionMsg = "successfully deleted.";
            transactionSuccess = true;
          } else {
            transactionMsg = `could not be deleted. ${response.errorMessage}`;
          }
        })
        .catch((e: any) => {
          console.error(e);
          setServerError(true);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg = "could not be deleted. A server error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Delete General display settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `General display settings ${transactionMsg}`,
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          if (transactionSuccess) {
            Reload();
          }
          setLoading(false);
        });
    }
  }

  //---------------END-----------------
  // list Item to add list in airline dropdown
  const ListItem = ({ item }: any) => (
    <span>
      <img
        width={60}
        height={20}
        src={`${AgentApiURL}/publicservices/images/airline/${item.value}`}
        alt="airlineimage"
      ></img>
      <strong>
        {" "}
        {item.text} ({item.value})
      </strong>
    </span>
  );
  // ----------------END----------------
  // Search Airline
  function OnSearch(val: string) {
    if (val.length > 1) {
      setIsLoading(true);
      const client: PublicServicesClient = new PublicServicesClient(
        new ApiCredentialsOptions(AgentApiURL, "")
      );
      client
        .getAirlinesByPrefix(val)
        .then((result) => {
          if (result.length > 0) {
            const map: any[] = [];
            result.forEach((e) => {
              map.push({ value: e.code!, text: e.hint!, image: "XXXX" });
            });
            setItems(map);
          } else {
            setItems([]);
          }
        })
        .catch(() => {
          setItems([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }
  // -----------END----------------
  // Set airline in smartcalender
  function OnIncludeUpdate(e: any[]) {
    setItems([]);
    const mapped = e as any[];
    // const newState: any = { ...props.State, IncludeAirlines: mapped };
    // props.Update(newState);
    setSmartCalender({ ...smartCalender, airline: e });
  }
  // -----------------END--------------
  // set Cabin class
  const handleChange = (e: any) => {
    const name = e.target.value;
    setCabinsSelect((prevState: any) => {
      return {
        ...prevState,
        [name]: {
          ...(prevState[name] = {
            ...prevState[name],
            Checked: e.target.checked,
          }),
        },
      };
    });
  };

  const handleChange2 = (e: any) => {
    const name = e.target.value;
    setCabinsSelect((prevState: any) => {
      return {
        ...prevState,
        [name]: {
          ...(prevState[name] = {
            ...prevState[name],
            preSelect: e.target.checked,
          }),
        },
      };
    });
  };

  useEffect(() => {
    handleChange;
    let cabinString = JSON.stringify(cabinsSelect);
    if (OtherSettingsKeyValue) {
      setOtherSettingsKeyValue({
        ...OtherSettingsKeyValue,
        fareDisplayCabin: cabinString,
      });
    }
  }, [
    cabinsSelect.Business.Checked,
    cabinsSelect.Economy.Checked,
    cabinsSelect.First.Checked,
    cabinsSelect.Premium.Checked,
    All,
  ]);
  // -----------END-----------
  // add cabin (state) data in otherSettingsKeyValue
  useEffect(() => {
    handleChange2;
    let cabinPreSelectString = JSON.stringify(cabinsSelect);
    if (OtherSettingsKeyValue) {
      setOtherSettingsKeyValue({
        ...OtherSettingsKeyValue,
        fareDisplayCabin: cabinPreSelectString,
      });
    }
  }, [
    cabinsSelect.Business.preSelect,
    cabinsSelect.Economy.preSelect,
    cabinsSelect.First.preSelect,
    cabinsSelect.Premium.preSelect,
    All,
  ]);
  // -----------END-----------
  useEffect(() => {
    if (All2) {
      setCabinsSelect((prevSate) => {
        return {
          ...prevSate,
          Premium: { ...prevSate.Premium, preSelect: true },
          First: { ...prevSate.First, preSelect: true },
          Business: { ...prevSate.Business, preSelect: true },
          Economy: { ...prevSate.Economy, preSelect: true },
        };
      });
    } else {
      setCabinsSelect((prevSate) => {
        return {
          ...prevSate,
          Premium: { ...prevSate.Premium, preSelect: false },
          First: { ...prevSate.First, preSelect: false },
          Business: { ...prevSate.Business, preSelect: false },
          Economy: { ...prevSate.Economy, preSelect: false },
        };
      });
    }

    // if (!cabins.Economy || !cabins.Premium || !cabins.Business || !cabins.First) {
    //   setAll(false)
    // }
  }, [All2]);
  // update Sate of all checkBox when all cabins are true or false
  useEffect(() => {
    if (All) {
      setCabinsSelect((prevSate) => {
        return {
          ...prevSate,
          Premium: { ...prevSate.Premium, Checked: true },
          First: { ...prevSate.First, Checked: true },
          Business: { ...prevSate.Business, Checked: true },
          Economy: { ...prevSate.Economy, Checked: true },
        };
      });
    } else {
      setCabinsSelect((prevSate) => {
        return {
          ...prevSate,
          Premium: { ...prevSate.Premium, Checked: false },
          First: { ...prevSate.First, Checked: false },
          Business: { ...prevSate.Business, Checked: false },
          Economy: { ...prevSate.Economy, Checked: false },
        };
      });
    }
    // if (!cabins.Economy || !cabins.Premium || !cabins.Business || !cabins.First) {
    //   setAll(false)
    // }
  }, [All]);
  // -------------END---------

  useEffect(() => {
    setSettings((prevState: any) => {
      return {
        ...prevState,
        b2cSettingsText: JSON.stringify(B2cSettings),
      };
    });
  }, [
    B2cSettings.HotelTab,
    B2cSettings.AirPortSearchType,
    B2cSettings?.ShowExtraAncillariesTabB2C,
    B2cSettings?.ShowLanguageDecimalTabB2C,
    B2cSettings?.ShowDateInput,
    B2cSettings?.ShowDefaultFilter,
    B2cSettings?.CabinClass?.Economy?.Checked,
    B2cSettings?.CabinClass?.Economy?.preSelect,
    B2cSettings?.CabinClass?.Premium?.Checked,
    B2cSettings?.CabinClass?.Premium?.preSelect,
    B2cSettings?.CabinClass?.Business?.Checked,
    B2cSettings?.CabinClass?.Business?.preSelect,
    B2cSettings?.CabinClass?.First?.Checked,
    B2cSettings?.CabinClass?.First?.preSelect,
    B2cSettings?.TermsConditionsLink,
    B2cSettings?.ShowBackgroundModalColor,
    B2cSettings?.showMarqueeText,
    B2cSettings?.showMarqueeFrom,
    B2cSettings?.showMarqueeTo,
    B2cSettings?.showBrandName,
    B2cSettings?.showPassengerTitleName,
    B2cSettings?.showFilterPosition,
    B2cSettings?.showAssistance,
    B2cSettings?.showUpsell,
    B2cSettings?.showUpsellB2B,
    B2cSettings?.showFareHeaderB2C,
    B2cSettings?.Style,
    B2cSettings?.ConfirmationText,
    B2cSettings?.priceOption,
    B2cSettings?.AllowedLanguage?.EN?.Checked,
    B2cSettings?.AllowedLanguage?.FR?.Checked,
    B2cSettings?.AllowedLanguage?.DE?.Checked,
    B2cSettings?.AllowedLanguage?.ES?.Checked,
    B2cSettings?.AllowedLanguage?.GA?.Checked,
    B2cSettings?.AllowedLanguage?.GR?.Checked,
    B2cSettings?.AllowedLanguage?.HU?.Checked,
    B2cSettings?.AllowedLanguage?.IT?.Checked,
    B2cSettings?.AllowedLanguage?.NL?.Checked,
    B2cSettings?.AllowedLanguage?.NO?.Checked,
    B2cSettings?.AllowedLanguage?.PL?.Checked,
    B2cSettings?.AllowedLanguage?.PT?.Checked,
    B2cSettings?.AllowedLanguage?.SE?.Checked,
    B2cSettings?.AllowedLanguage?.TK?.Checked,
    B2cSettings?.RouteFilter,
    B2cSettings?.filtersOrderB2B,
    B2cSettings?.filtersOrderB2C,
    B2cSettings?.fareShowB2B?.showFlight?.Checked,
    B2cSettings?.fareShowB2B?.showCalculation?.Checked,
    B2cSettings?.fareShowB2B?.showFlightTime?.Checked,
    B2cSettings?.allowedBookingManagerCouponColumns 
  ]);

  // ***************************************filter order section B2B************************************

  useEffect(() => {
    setB2cSettings({
      ...B2cSettings,
      filtersOrderB2B: filtersB2B,
    })
  }, [filtersBoolB2B])


  // Handle checkbox state change
  const handleChangeFilterOption = (id: any) => {
    setFilterB2B((prevFilter) =>
      prevFilter.map((filter) =>
        filter.label === id ? { ...filter, checked: !filter.checked } : filter
      )
    );
    setFilterBoolB2B((prev: boolean) => !prev);
  };

  // Handle drag end and update the order field
  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedFilters = Array.from(filtersB2B);
    const [removed] = reorderedFilters.splice(result.source.index, 1);
    reorderedFilters.splice(result.destination.index, 0, removed);

    // Update the order based on the new positions
    const updatedFilters = reorderedFilters.map((filter, index) => ({
      ...filter,
      order: index + 1, // New order based on the index
    }));

    setFilterB2B(updatedFilters);
    setFilterBoolB2B((prev: boolean) => !prev);
  };


  // ***************************************filter order section B2C************************************

  useEffect(() => {
    setB2cSettings({
      ...B2cSettings,
      filtersOrderB2C: filtersB2C,
    })
  }, [filtersBoolB2C])


  // Handle checkbox state change
  const handleChangeFilterOptionB2C = (id: any) => {
    setFilterB2C((prevFilter) =>
      prevFilter.map((filter) =>
        filter.label === id ? { ...filter, checked: !filter.checked } : filter
      )
    );
    setFilterBoolB2C((prev: boolean) => !prev);
  };

  // Handle drag end and update the order field
  const handleDragEndB2C = (result: any) => {
    if (!result.destination) return;

    const reorderedFilters = Array.from(filtersB2C);
    const [removed] = reorderedFilters.splice(result.source.index, 1);
    reorderedFilters.splice(result.destination.index, 0, removed);

    // Update the order based on the new positions
    const updatedFilters = reorderedFilters.map((filter, index) => ({
      ...filter,
      order: index + 1, // New order based on the index
    }));

    setFilterB2C(updatedFilters);
    setFilterBoolB2C((prev: boolean) => !prev);
  };

  // ***************************************separating B2B - B2C setting UI************************************
  const location = useLocation();

  const getLastPathSegment = () => {
    const path = location.pathname;
    const segments = path?.split('/');
    return segments[segments?.length - 1];
  };

  // *************************************** B2B  booking manager coupon fields ************************************

  function OnToggleColumnStatus(toggleColumn: AgentPlusBookingManagerColumns) {
    if (B2cSettings) {
      let oldArr =  [...B2cSettings.allowedBookingManagerCouponColumns];
      const index = oldArr.indexOf(toggleColumn);
  
      if (index === -1) {
        // Add the column if it's not already included
        oldArr.push(toggleColumn);
      } else {
        // Remove the column if it already exists
        oldArr.splice(index, 1);
      }
  
      // Update the settings with the modified allowedBookingManagerCouponColumns array
      setB2cSettings({ ...B2cSettings, allowedBookingManagerCouponColumns: oldArr });
    }
  }
  

  return (
    <Box boxShadow={ReadOnly ? undefined : 3}>
      <Card style={{ opacity: ReadOnly ? 0.5 : 1 }}>
        <CardHeader
          title={
            <span>
              {props.Applies && (
                <span>
                  <ApplyIndicator />
                </span>
              )}
              {props.IsOverwritten && (
                <span>
                  <OverriddenIndicator />
                </span>
              )}
              <span>General display settings for </span>
              <WebLevelLabel Level={WebLevel} Name={LevelName} />{" "}
              {/* {props.IsOverwritten ? "Overwritten" : ""} */}
            </span>
          }
        />
        {!loading && (
          <CardContent>
            {Settings && isSetting ? (
              <Grid container>
                {serverError && <ErrorMessages ServerError={true} />}
                {validationState && validationState.errorSummary && (
                  <ErrorMessages Messages={validationState.errorSummary} />
                )}
                {
                  getLastPathSegment() == "generalB2B" ?
                    <>
                      <Grid item xs={12} style={{ margin: "10px 0px" }}>
                        <h2 className="font-monospace">
                          General display settings for B2B
                        </h2>
                      </Grid>

                      <Grid item xs={3}>
                        <Box>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              Show booking manager tab{" "}
                              <HelpTextButton
                                HelpText={
                                  HelpTexts.DisplaySettingsShowBookingmanagerTabHelpText
                                }
                              />
                            </FormLabel>
                            <RadioGroup
                              aria-label="position"
                              name="position"
                              value={Settings?.bookingManagerTab}
                              onChange={(val) => {
                                setSettings({
                                  ...Settings,
                                  bookingManagerTab: parseInt(val.target.value),
                                });
                              }}
                              row
                            >
                              <FormControlLabel
                                value={DisplaySettingAccess.AdminOnly}
                                control={<Radio color="primary" />}
                                label="Admin only"
                                labelPlacement="bottom"
                              />
                              <FormControlLabel
                                value={DisplaySettingAccess.AllUsers}
                                control={<Radio color="primary" />}
                                label="All"
                                labelPlacement="bottom"
                              />
                              <FormControlLabel
                                value={DisplaySettingAccess.None}
                                control={<Radio color="primary" />}
                                label="None"
                                labelPlacement="bottom"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              Show fare query tab{" "}
                              <HelpTextButton
                                HelpText={
                                  HelpTexts.DisplaySettingsShowFareQueryTabHelpText
                                }
                              />
                            </FormLabel>
                            <RadioGroup
                              aria-label="position"
                              name="position"
                              value={Settings?.fareQueryTab}
                              onChange={(val) => {
                                setSettings({
                                  ...Settings,
                                  fareQueryTab: parseInt(val.target.value),
                                });
                              }}
                              row
                            >
                              <FormControlLabel
                                value={DisplaySettingAccess?.AdminOnly}
                                control={<Radio color="primary" />}
                                label="Admin only"
                                labelPlacement="bottom"
                              />
                              <FormControlLabel
                                value={DisplaySettingAccess?.AllUsers}
                                control={<Radio color="primary" />}
                                label="All"
                                labelPlacement="bottom"
                              />
                              <FormControlLabel
                                value={DisplaySettingAccess?.None}
                                control={<Radio color="primary" />}
                                label="None"
                                labelPlacement="bottom"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              Show hotel tab{" "}
                              <HelpTextButton
                                HelpText={
                                  HelpTexts.DisplaySettingsShowHotelTabHelpText
                                }
                              />
                            </FormLabel>
                            <RadioGroup
                              aria-label="position"
                              name="position"
                              value={OtherSettingsKeyValue?.HotelTab}
                              onChange={(val) => {
                                setOtherSettingsKeyValue({
                                  ...OtherSettingsKeyValue,
                                  HotelTab: val.target.value,
                                });
                              }}
                              row
                            >
                              <FormControlLabel
                                value={ShowHotelTab.AdminOnly}
                                control={<Radio color="primary" />}
                                label="Admin only"
                                labelPlacement="bottom"
                              />
                              <FormControlLabel
                                value={ShowHotelTab.AllUsers}
                                control={<Radio color="primary" />}
                                label="All"
                                labelPlacement="bottom"
                              />
                              <FormControlLabel
                                value={ShowHotelTab.None}
                                control={<Radio color="primary" />}
                                label="None"
                                labelPlacement="bottom"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              Show cabin{" "}
                              <HelpTextButton
                                HelpText={
                                  HelpTexts.DisplaySettingsShowCabinContainerHelpText
                                }
                              />
                            </FormLabel>
                            <RadioGroup
                              aria-label="position"
                              name="position"
                              value={OtherSettingsKeyValue?.CabinContainer}
                              onChange={(val) => {
                                setOtherSettingsKeyValue({
                                  ...OtherSettingsKeyValue,
                                  CabinContainer: val.target.value,
                                });
                              }}
                              row
                            >
                              <FormControlLabel
                                value={ShowCabinContainer?.AdminOnly}
                                control={<Radio color="primary" />}
                                label="Admin only"
                                labelPlacement="bottom"
                              />
                              <FormControlLabel
                                value={ShowCabinContainer?.AllUsers}
                                control={<Radio color="primary" />}
                                label="All"
                                labelPlacement="bottom"
                              />
                              <FormControlLabel
                                value={ShowCabinContainer?.None}
                                control={<Radio color="primary" />}
                                label="None"
                                labelPlacement="bottom"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                      {OtherSettingsKeyValue && (
                        <Grid style={{ margin: "25px 5px" }} item xs={3}>
                          <Box>
                            <FormControl fullWidth>
                              <FormLabel component="legend">
                                Auto refresh{" "}
                                <HelpTextButton
                                  HelpText={
                                    HelpTexts.DisplaySettingsAutoRefreshHelpText
                                  }
                                />
                              </FormLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={OtherSettingsKeyValue?.refreshTime}
                                label="Auto Refresh Time"
                                onChange={(val) => {
                                  setOtherSettingsKeyValue({
                                    ...OtherSettingsKeyValue,
                                    refreshTime: val.target.value,
                                  });
                                }}
                              >
                                <MenuItem value={0}>Never</MenuItem>
                                <MenuItem value={1}>1 Minute</MenuItem>
                                <MenuItem value={5}>5 Minute</MenuItem>
                                <MenuItem value={10}>10 Minutes</MenuItem>
                                <MenuItem value={30}>30 Minutes</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                      )}
                      <Grid item xs={12} style={{ margin: "10px 0px" }}>
                        <Divider />
                      </Grid>
                      {/* OtherSettingsKeyValue start */}
                      {OtherSettingsKeyValue ? (
                        <Grid container>
                          <Grid style={{ margin: "10px" }} item xs={4}>
                            <Box mb={2}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  Show menu on page{" "}
                                  <HelpTextButton
                                    HelpText={
                                      HelpTexts.DisplaySettingsShowMenuHelpText
                                    }
                                  ></HelpTextButton>
                                </FormLabel>
                                <RadioGroup
                                  aria-label="position"
                                  name="position"
                                  value={OtherSettingsKeyValue?.menuPosition}
                                  onChange={(val) => {
                                    setOtherSettingsKeyValue({
                                      ...OtherSettingsKeyValue,
                                      menuPosition: val.target.value,
                                    });
                                  }}
                                  row
                                >
                                  <FormControlLabel
                                    value={DisplaySideBarSettingAccess?.Left}
                                    control={<Radio color="primary" />}
                                    label="Left"
                                    labelPlacement="bottom"
                                  />
                                  <FormControlLabel
                                    value={DisplaySideBarSettingAccess?.Right}
                                    control={<Radio color="primary" />}
                                    label="Right"
                                    labelPlacement="bottom"
                                  />
                                  <FormControlLabel
                                    value={DisplaySideBarSettingAccess?.Off}
                                    control={<Radio color="primary" />}
                                    label="Off"
                                    labelPlacement="bottom"
                                  />
                                </RadioGroup>
                                {/* <MuiColorInput value={color} onChange={e => { setColor(e) }} /> */}
                              </FormControl>
                              <hr
                                style={{ width: "50%", margin: "10px 0px" }}
                                className="MuiDivider-root"
                              />
                              <div style={{ display: "flex" }}>
                                <Box m={2} className="colors">
                                  <FormLabel component="legend">
                                    Menu background
                                  </FormLabel>
                                  <TextField
                                    value={bgColor}
                                    type={"color"}
                                    onChange={(e) => setBgColor(e.target.value)}
                                  />
                                </Box>
                                <Box m={2} className="colors">
                                  <FormLabel component="legend">Menu color</FormLabel>
                                  <TextField
                                    value={color}
                                    type="color"
                                    onChange={(e) => setColor(e.target.value)}
                                  />
                                </Box>
                              </div>
                            </Box>
                          </Grid>
                          <Grid style={{ margin: "10px" }} item xs={3}>
                            <Box mb={2}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  Show fare-display header{" "}
                                  <HelpTextButton
                                    HelpText={HelpTexts.DisplayFareHeader}
                                  />
                                </FormLabel>
                                <RadioGroup
                                  aria-label="position"
                                  name="position"
                                  value={OtherSettingsKeyValue?.fareDisplayHeader}
                                  onChange={(val) => {
                                    setOtherSettingsKeyValue({
                                      ...OtherSettingsKeyValue,
                                      fareDisplayHeader: val.target.value,
                                    });
                                  }}
                                  row
                                >
                                  <FormControlLabel
                                    value={ShowFareHeader?.On}
                                    control={<Radio color="primary" />}
                                    label="On"
                                    labelPlacement="bottom"
                                  />
                                  <FormControlLabel
                                    value={ShowFareHeader?.Off}
                                    control={<Radio color="primary" />}
                                    label="Off"
                                    labelPlacement="bottom"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </Grid>
                          {smartCalender && Settings && (
                            <Grid
                              style={{ margin: "10px", minWidth: "450px" }}
                              className="smartCalenderContainerCss"
                              item
                              xs={4}
                            >
                              <Box>
                                <FormControl component="fieldset">
                                  <FormLabel component="legend">
                                    Show smart calender{" "}
                                    <HelpTextButton
                                      HelpText={HelpTexts.SmartCalender}
                                    />
                                  </FormLabel>
                                  <RadioGroup
                                    style={{}}
                                    aria-label="position"
                                    name="position"
                                    value={smartCalender?.showSmartCalender}
                                    onChange={(val) => {
                                      setSmartCalender({
                                        ...smartCalender,
                                        showSmartCalender: val.target.value,
                                      });
                                    }}
                                    row
                                  >
                                    <FormControlLabel
                                      value={SmartCalenderAccess?.AdminOnly}
                                      control={<Radio color="primary" />}
                                      label="Admin only"
                                      labelPlacement="bottom"
                                    />
                                    <FormControlLabel
                                      value={SmartCalenderAccess?.AllUsers}
                                      control={<Radio color="primary" />}
                                      label="All"
                                      labelPlacement="bottom"
                                    />
                                    <FormControlLabel
                                      value={SmartCalenderAccess?.None}
                                      control={<Radio color="primary" />}
                                      label="None"
                                      labelPlacement="bottom"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Box>
                              <div
                                className={
                                  smartCalender.showSmartCalender ==
                                    SmartCalenderAccess.None
                                    ? "isDisable"
                                    : ""
                                }
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    marginTop: "4px",
                                    alignItems: "center",
                                  }}
                                >
                                  <FormControlLabel
                                    className="flightDateLabel"
                                    control={
                                      <Checkbox
                                        checked={flightDate}
                                        onChange={() => {
                                          setFlightDate(!flightDate);
                                          setSmartCalender({
                                            ...smartCalender,
                                            flightDates: !flightDate,
                                          });
                                        }}
                                      />
                                    }
                                    label="Highlight flight dates :"
                                  />
                                  <Multiselect
                                    containerClassName="form-control"
                                    onChange={(e) => {
                                      OnIncludeUpdate(e);
                                    }}
                                    textField={(item: any) =>
                                      `${item.text} (${item.value})`
                                    }
                                    defaultValue={smartCalender?.airline}
                                    busy={isLoading}
                                    filter={false}
                                    data={items}
                                    onSearch={(e) => OnSearch(e)}
                                    renderListItem={ListItem}
                                    placeholder="calender airlines"
                                    dataKey="includeairlines"
                                    messages={{
                                      emptyList: "type to see airlines...",
                                    }}
                                  />
                                </div>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={allotmentDate}
                                      onChange={() => {
                                        setAllotmentDate(!allotmentDate);
                                        setSmartCalender({
                                          ...smartCalender,
                                          allotementDates: !allotmentDate,
                                        });
                                      }}
                                    />
                                  }
                                  label="Highlight allotment dates"
                                />
                              </div>
                            </Grid>
                          )}
                          <Grid
                            style={{ margin: "10px", minWidth: "400px" }}
                            className=""
                            item
                            xs={4}
                          >
                            <Box mb={2}>
                              <FormLabel
                                style={{ paddingBottom: "8px" }}
                                component="legend"
                              >
                                Show cabin classes
                                <HelpTextButton
                                  HelpText={HelpTexts.CabinClassViewAndSelection}
                                />
                              </FormLabel>
                              <FormControl component="fieldset">
                                <FormLabel
                                  className="showCabinCss"
                                  component="legend"
                                >
                                  Show cabin
                                </FormLabel>
                                <FormGroup>
                                  <FormControlLabel
                                    className="flexChild labelContainer"
                                    label="All"
                                    control={
                                      <Checkbox
                                        checked={
                                          cabinsSelect?.Economy?.Checked &&
                                          cabinsSelect?.Premium?.Checked &&
                                          cabinsSelect?.Business?.Checked &&
                                          cabinsSelect?.First?.Checked
                                        }
                                        indeterminate={!All}
                                        onChange={() => {
                                          setAll(!All);
                                        }}
                                      />
                                    }
                                  />

                                  <FormControlLabel
                                    className="flexChild labelContainer"
                                    control={
                                      <Checkbox
                                        checked={cabinsSelect.Economy.Checked}
                                        onChange={handleChange}
                                        value={cabinClasses?.Economy}
                                      />
                                    }
                                    label="Economy"
                                  />
                                  <FormControlLabel
                                    className="flexChild labelContainer"
                                    control={
                                      <Checkbox
                                        checked={cabinsSelect.Premium.Checked}
                                        onChange={handleChange}
                                        value={cabinClasses?.Premium}
                                      />
                                    }
                                    label="Premium"
                                  />
                                  <FormControlLabel
                                    className="flexChild labelContainer"
                                    control={
                                      <Checkbox
                                        checked={cabinsSelect.Business.Checked}
                                        onChange={handleChange}
                                        value={cabinClasses?.Business}
                                      />
                                    }
                                    label="Business"
                                  />
                                  <FormControlLabel
                                    className="flexChild labelContainer"
                                    control={
                                      <Checkbox
                                        checked={cabinsSelect.First.Checked}
                                        onChange={handleChange}
                                        value={cabinClasses?.First}
                                      />
                                    }
                                    label="First"
                                  />
                                </FormGroup>
                              </FormControl>
                              <FormControl
                                component="fieldset"
                                className="preSelectCont"
                              >
                                <FormLabel component="legend">Preselect</FormLabel>
                                <FormGroup>
                                  <FormControlLabel
                                    className=" rightCheckBoxAlignmentCss"
                                    label=""
                                    control={
                                      <Checkbox
                                        checked={
                                          cabinsSelect?.Economy?.preSelect &&
                                          cabinsSelect?.Premium?.preSelect &&
                                          cabinsSelect?.Business?.preSelect &&
                                          cabinsSelect?.First?.preSelect
                                        }
                                        indeterminate={!All2}
                                        onChange={() => {
                                          setAll2(!All2);
                                        }}
                                      />
                                    }
                                  />
                                  <FormControlLabel
                                    className=" rightCheckBoxAlignmentCss"
                                    control={
                                      <Checkbox
                                        checked={cabinsSelect.Economy.preSelect}
                                        onChange={handleChange2}
                                        value={cabinClasses.Economy}
                                      />
                                    }
                                    label=""
                                  />
                                  <FormControlLabel
                                    className="rightCheckBoxAlignmentCss "
                                    control={
                                      <Checkbox
                                        checked={cabinsSelect.Premium.preSelect}
                                        onChange={handleChange2}
                                        value={cabinClasses.Premium}
                                      />
                                    }
                                    label=""
                                  />
                                  <FormControlLabel
                                    className=" rightCheckBoxAlignmentCss"
                                    control={
                                      <Checkbox
                                        checked={cabinsSelect.Business.preSelect}
                                        onChange={handleChange2}
                                        value={cabinClasses.Business}
                                      />
                                    }
                                    label=""
                                  />
                                  <FormControlLabel
                                    className=" rightCheckBoxAlignmentCss"
                                    control={
                                      <Checkbox
                                        checked={cabinsSelect.First.preSelect}
                                        onChange={handleChange2}
                                        value={cabinClasses.First}
                                      />
                                    }
                                    label=""
                                  />
                                </FormGroup>
                              </FormControl>
                            </Box>

                          </Grid>
                          <Grid
                            style={{ margin: "10px", minWidth: "400px" }}
                            className=""
                            item
                            xs={3}
                          >
                            <FormLabel
                              style={{ paddingBottom: "8px" }}
                              component="legend"
                            >
                              Passenger
                              <HelpTextButton
                                HelpText={HelpTexts.CabinClassViewAndSelection}
                              />
                            </FormLabel>
                            <Box mb={2}>
                              <FormLabel
                                style={{ paddingBottom: "8px" }}
                                component="legend"
                              >
                                Default gender
                              </FormLabel>
                              <FormControl component="fieldset">
                                <RadioGroup
                                  aria-label="position"
                                  name="position"
                                  value={
                                    OtherSettingsKeyValue?.PassengerInfo
                                      ?.PassengerGender
                                  }
                                  onChange={(val) => {
                                    setOtherSettingsKeyValue({
                                      ...OtherSettingsKeyValue,
                                      PassengerInfo: {
                                        ...OtherSettingsKeyValue.PassengerInfo,
                                        PassengerGender: val.target.value,
                                      },
                                    });
                                  }}
                                  row
                                >
                                  <FormControlLabel
                                    value={DefaultPassengerGender?.Unselected}
                                    control={<Radio color="primary" />}
                                    label="Unselected"
                                    labelPlacement="bottom"
                                  />
                                  <FormControlLabel
                                    value={DefaultPassengerGender?.Male}
                                    control={<Radio color="primary" />}
                                    label="Male"
                                    labelPlacement="bottom"
                                  />
                                  <FormControlLabel
                                    value={DefaultPassengerGender?.FeMale}
                                    control={<Radio color="primary" />}
                                    label="Female"
                                    labelPlacement="bottom"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                            <Box mb={2}>
                              <FormLabel
                                style={{ paddingBottom: "8px" }}
                                component="legend"
                              >
                                Date of birth: Mandatory
                              </FormLabel>
                              <FormControl component="fieldset">
                                <RadioGroup
                                  aria-label="position"
                                  name="position"
                                  value={
                                    OtherSettingsKeyValue?.PassengerInfo?.PassengerDOB
                                  }
                                  onChange={(val) => {
                                    setOtherSettingsKeyValue({
                                      ...OtherSettingsKeyValue,
                                      PassengerInfo: {
                                        ...OtherSettingsKeyValue.PassengerInfo,
                                        PassengerDOB: val.target.value,
                                      },
                                    });
                                  }}
                                  row
                                >
                                  <FormControlLabel
                                    value={DefaultPassengerDOB?.Yes}
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                    labelPlacement="bottom"
                                  />
                                  <FormControlLabel
                                    value={DefaultPassengerDOB?.No}
                                    control={<Radio color="primary" />}
                                    label="No"
                                    labelPlacement="bottom"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </Grid>


                          <Grid style={{ margin: "10px", }} item>
                            <Box mb={2}>
                              <FormLabel
                                style={{ paddingBottom: "8px" }}
                                component="legend"
                              >
                                Date of birth
                              </FormLabel>
                              <FormControl component="fieldset">
                                <RadioGroup
                                  aria-label="position"
                                  name="position"
                                  value={OtherSettingsKeyValue?.PassengerInfo?.DOB}
                                  onChange={(event) => {
                                    const newValue = Number(event.target.value);
                                    setOtherSettingsKeyValue((prev: any) => ({
                                      ...prev,
                                      PassengerInfo: {
                                        ...prev.PassengerInfo,
                                        DOB: newValue,
                                      },
                                    }));
                                  }}
                                  row
                                >
                                  <FormControlLabel
                                    value={DOBNameField.ForPaxDobOnly}
                                    control={<Radio color="primary" />}
                                    label="For Pax Dob Only"
                                    labelPlacement="bottom"
                                  />
                                  <FormControlLabel
                                    value={DOBNameField.ForApisOnly}
                                    control={<Radio color="primary" />}
                                    label="For Apis Only"
                                    labelPlacement="bottom"
                                  />
                                  <FormControlLabel
                                    value={DOBNameField.ForPaxDobAndApis}
                                    control={<Radio color="primary" />}
                                    label="For Pax Dob And Apis"
                                    labelPlacement="bottom"
                                  />
                                  <FormControlLabel
                                    value={DOBNameField.DoNotUse}
                                    control={<Radio color="primary" />}
                                    label="Do Not Use"
                                    labelPlacement="bottom"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </Grid>


                          <Grid
                            style={{ margin: "10px", minWidth: "400px" }}
                            className="FieldcontainerCss"
                            item
                            xs={2}
                          >
                            <FormLabel
                              style={{ paddingBottom: "8px" }}
                              component="legend"
                            >
                              Field container
                              <HelpTextButton HelpText={HelpTexts.fieldContainer} />
                            </FormLabel>
                            <Box mb={2}>
                              <FormControl component="fieldset">
                                <TextareaAutosize
                                  className="textInputResize"
                                  minRows={10}
                                  value={
                                    OtherSettingsKeyValue?.FieldContainer?.Container
                                  }
                                  onChange={(val) => {
                                    setOtherSettingsKeyValue({
                                      ...OtherSettingsKeyValue,
                                      FieldContainer: {
                                        Container: val.target.value,
                                      },
                                    });
                                  }}
                                />
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid
                            style={{ margin: "10px 30px", minWidth: "200px" }}
                            className="FieldcontainerCss"
                            item
                            xs={3}
                          >
                            <FormLabel
                              style={{ paddingBottom: "8px" }}
                              component="legend"
                            >
                              Marquee text
                              <HelpTextButton HelpText={HelpTexts.DisplaySettingsB2CMarqueeText} />
                            </FormLabel>
                            <Box mb={3}>
                              <FormControl component="fieldset" style={{ minWidth: '100%' }}>
                                <TextareaAutosize
                                  className="textInputResize"
                                  minRows={6}

                                  value={
                                    B2cSettings?.showMarqueeText
                                  }
                                  onChange={(val) => {
                                    setB2cSettings({
                                      ...B2cSettings,
                                      showMarqueeText: val.target.value,
                                    })
                                  }}
                                />
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid
                            style={{ margin: "10px 30px", minWidth: "200px" }}
                            className="FieldcontainerCss"
                            item
                            xs={3}>
                            <FormLabel
                              style={{ paddingBottom: "8px" }}
                              component="legend"
                            >
                              Marquee from
                              <HelpTextButton HelpText={HelpTexts.DisplaySettingsB2CLinkContainer} />
                            </FormLabel>
                            <Box mb={4}>
                              <FormControl component="fieldset">
                                <input
                                  value={B2cSettings?.showMarqueeFrom}
                                  type="datetime-local"
                                  className="form-control datetime-picker"
                                  id="datetimePicker"
                                  aria-label="Date and time"
                                  onChange={(val) => {
                                    setB2cSettings({
                                      ...B2cSettings,
                                      showMarqueeFrom: val.target.value,
                                    });
                                  }}
                                />
                              </FormControl>
                            </Box>
                            <FormLabel
                              style={{ paddingBottom: "8px" }}
                              component="legend"
                            >
                              Marquee to
                              <HelpTextButton HelpText={HelpTexts.DisplaySettingsB2CLinkContainer} />
                            </FormLabel>
                            <Box mb={4}>
                              <FormControl component="fieldset">
                                <input value={B2cSettings?.showMarqueeTo}
                                  type="datetime-local" className="form-control datetime-picker" id="datetimePicker" aria-label="Date and time"
                                  onChange={(val) => {
                                    setB2cSettings({
                                      ...B2cSettings,
                                      showMarqueeTo: val.target.value,
                                    })
                                  }}
                                />
                              </FormControl>
                            </Box>
                          </Grid>
                        </Grid>
                      ) : null}
                      {/* OtherSettingsKeyValue end */}
                      <Grid item xs={12} style={{ margin: "10px 0px" }}>
                        <Divider />
                      </Grid>
                      <Grid style={{ margin: "25px" }} item xs={8}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                              <InputLabel>
                                Dateformat{" "}
                                <HelpTextButton
                                  HelpText={
                                    HelpTexts.DisplaySettingsDateFormatHelpText
                                  }
                                />
                              </InputLabel>
                              <Select
                                value={Settings?.dateFormat}
                                onChange={(e) =>
                                  setSettings({
                                    ...Settings,
                                    dateFormat: e.target.value as string,
                                  })
                                }
                              >
                                <MenuItem value={"dd.MM.yyyy"}>dd.mm.yyyy</MenuItem>
                                <MenuItem value={"dd/MM/yyyy"}>dd/mm/yyyy</MenuItem>
                                <MenuItem value={"MM/dd/yyyy"}>mm/dd/yyyy</MenuItem>
                                <MenuItem value={"yyyy-mm-dd"}>yyyy-mm-dd</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                              <InputLabel>
                                Locale{" "}
                                <HelpTextButton
                                  HelpText={HelpTexts.DisplaySettingsLocaleHelpText}
                                />
                              </InputLabel>
                              <Select
                                value={Settings?.locale}
                                onChange={(e) =>
                                  setSettings({
                                    ...Settings,
                                    locale: e.target.value as string,
                                  })
                                }
                              >
                                <MenuItem value={"en-GB"}>
                                  English (United Kingdom)
                                </MenuItem>
                                <MenuItem value={"de-DE"}>German (Germany)</MenuItem>
                                <MenuItem value={"de-CH"}>
                                  German (Switzerland)
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid style={{ margin: "20px" }} item xs={12} md={4}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">
                            Start page{" "}
                            <HelpTextButton
                              HelpText={HelpTexts.DisplaySettingsStartPageHelpText}
                            />
                          </FormLabel>
                          <RadioGroup
                            aria-label="position"
                            name="position"
                            value={Settings.startPage}
                            onChange={(val) => {
                              setSettings({
                                ...Settings,
                                startPage: parseInt(val.target.value),
                              });
                            }}
                            row
                          >
                            <FormControlLabel
                              value={StartPage?.BookingManager}
                              control={<Radio color="primary" />}
                              label="Booking manager"
                              labelPlacement="bottom"
                            />
                            <FormControlLabel
                              value={StartPage?.Query}
                              control={<Radio color="primary" />}
                              label="Query"
                              labelPlacement="bottom"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {/* filter options sorting */}
                      <Grid
                        style={{ margin: "10px", minWidth: "400px" }}
                        className=""
                        item
                        xs={4}
                      >
                        <Box mb={2} className="filterOrderContainer">
                          <FormLabel
                            style={{ paddingBottom: "8px" }}
                            component="legend"
                          >
                            Filters
                            <HelpTextButton
                              HelpText={HelpTexts.FiltersOrderHelperTextB2B}
                            />
                          </FormLabel>
                          <FormControl component="fieldset">
                            <DragDropContext onDragEnd={handleDragEnd}>
                              <Droppable droppableId="filter-list">
                                {(provided) => (
                                  <FormGroup {...provided.droppableProps} ref={provided.innerRef}>
                                    {filtersB2B?.map((filter, index) => (
                                      <Draggable key={filter?.label} draggableId={filter?.label} index={index}>
                                        {(provided) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <FormControlLabel
                                              className="flexChild labelContainer"
                                              control={
                                                <Checkbox
                                                  checked={filter?.checked}
                                                  onChange={() => handleChangeFilterOption(filter?.label)}
                                                />
                                              }
                                              label={filter.label}
                                            />
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </FormGroup>
                                )}
                              </Droppable>
                            </DragDropContext>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box>
                          <FormLabel
                            style={{ paddingBottom: "8px" }}
                            component="legend"
                          >
                            Flight details
                            <HelpTextButton
                              HelpText={HelpTexts.FareCollapseViewAndSelection}
                            />
                          </FormLabel>
                          <FormControl component="fieldset">
                            <FormLabel
                              className="showCabinCss"
                              component="legend"
                            >

                            </FormLabel>
                            <FormGroup>
                              <FormControlLabel
                                className="flexChild labelContainer"
                                control={
                                  <Checkbox
                                    checked={
                                      B2cSettings?.fareShowB2B?.showFlight?.Checked
                                    } // Optional chaining used to prevent accessing properties on undefined/null
                                    onChange={(e) =>
                                      setB2cSettings((prevState) => ({
                                        ...prevState,
                                        fareShowB2B: {
                                          ...prevState.fareShowB2B,
                                          showFlight: {
                                            ...prevState.fareShowB2B?.showFlight,
                                            Checked: e.target.checked,
                                          },
                                        },
                                      }))
                                    }
                                    value={FareShowValue?.showFlight}
                                  />
                                }
                                label="Show flight"
                              />
                              <FormControlLabel
                                className="flexChild labelContainer"
                                control={
                                  <Checkbox
                                    checked={
                                      B2cSettings?.fareShowB2B?.showCalculation?.Checked} // Optional chaining used to prevent accessing properties on undefined/null
                                    onChange={(e) =>
                                      setB2cSettings((prevState) => ({
                                        ...prevState,
                                        fareShowB2B: {
                                          ...prevState.fareShowB2B,
                                          showCalculation: {
                                            ...prevState.fareShowB2B?.showCalculation,
                                            Checked: e.target.checked,
                                          },
                                        },
                                      }))
                                    }
                                    value={FareShowValue?.showCalculation}
                                  />
                                }
                                label="Show calculation"
                              />
                              <FormControlLabel
                                className="flexChild labelContainer"
                                control={
                                  <Checkbox
                                    checked={
                                      B2cSettings?.fareShowB2B?.showFlightTime?.Checked} // Optional chaining used to prevent accessing properties on undefined/null
                                    onChange={(e) =>
                                      setB2cSettings((prevState) => ({
                                        ...prevState,
                                        fareShowB2B: {
                                          ...prevState.fareShowB2B,
                                          showFlightTime: {
                                            ...prevState.fareShowB2B?.showFlightTime,
                                            Checked: e.target.checked,
                                          },
                                        },
                                      }))
                                    }
                                    value={FareShowValue?.showFlightTime}
                                  />
                                }
                                label="Show flight time"
                              />
                            </FormGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                          <br />
                      </Grid>
                      <Grid item xs={3}>
                        <Box>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              Show Upsell {" "}
                              <HelpTextButton
                                HelpText={
                                  HelpTexts.DisplaySettingsB2CUpsell
                                }
                              />
                            </FormLabel>
                            <RadioGroup
                              aria-label="position"
                              name="position"
                              value={
                                B2cSettings?.showUpsellB2B != null
                                  ? B2cSettings?.showUpsellB2B
                                  : ShowDefaultUpsell.On
                              }
                              onChange={(e) =>
                                setB2cSettings({
                                  ...B2cSettings,
                                  showUpsellB2B: e.target.value,
                                })
                              }
                              row
                            >
                              <FormControlLabel
                                value={ShowDefaultUpsellB2B.On}
                                control={<Radio color="primary" />}
                                label="On"
                                labelPlacement="bottom"
                              />
                              <FormControlLabel
                                value={ShowDefaultUpsellB2B.Off}
                                control={<Radio color="primary" />}
                                label="Off"
                                labelPlacement="bottom"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                      
                      <Grid item xs={4} container alignItems="center" alignContent="flex-start">
                        <Grid item xs={12}>
                          <FormLabel
                              style={{ paddingBottom: "8px" }}
                              component="legend"
                            >
                              Show coupon column 
                              <HelpTextButton
                                HelpText={HelpTexts.CouponColumnViewAndSelection}
                              />
                            </FormLabel>
                        </Grid>
                        <Grid item xs={6}>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={B2cSettings?.allowedBookingManagerCouponColumns !== undefined ? B2cSettings?.allowedBookingManagerCouponColumns.includes(AgentPlusBookingManagerColumns.ID):false}
                                  onChange={() => {
                                    OnToggleColumnStatus(AgentPlusBookingManagerColumns.ID);
                                  }}
                                />
                              }
                              label="ID"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={B2cSettings?.allowedBookingManagerCouponColumns !== undefined ? B2cSettings?.allowedBookingManagerCouponColumns.includes(AgentPlusBookingManagerColumns.Type):false}
                                  onChange={() => {
                                    OnToggleColumnStatus(AgentPlusBookingManagerColumns.Type);
                                  }}
                                />
                              }
                              label="Type"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={B2cSettings?.allowedBookingManagerCouponColumns !== undefined ? B2cSettings?.allowedBookingManagerCouponColumns.includes(AgentPlusBookingManagerColumns.TypeCode):false}
                                  onChange={() => {
                                    OnToggleColumnStatus(AgentPlusBookingManagerColumns.TypeCode);
                                  }}
                                />
                              }
                              label="Type Code"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={B2cSettings?.allowedBookingManagerCouponColumns !== undefined ? B2cSettings?.allowedBookingManagerCouponColumns.includes(AgentPlusBookingManagerColumns.TypeID):false}
                                  onChange={() => {
                                    OnToggleColumnStatus(AgentPlusBookingManagerColumns.TypeID);
                                  }}
                                />
                              }
                              label="Type ID"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={B2cSettings?.allowedBookingManagerCouponColumns !== undefined ? B2cSettings?.allowedBookingManagerCouponColumns.includes(AgentPlusBookingManagerColumns.TypeText):false}
                                  onChange={() => {
                                    OnToggleColumnStatus(AgentPlusBookingManagerColumns.TypeText);
                                  }}
                                />
                              }
                              label="Type Text"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={B2cSettings?.allowedBookingManagerCouponColumns !== undefined ? B2cSettings?.allowedBookingManagerCouponColumns.includes(AgentPlusBookingManagerColumns.Carrier):false}
                                  onChange={() => {
                                    OnToggleColumnStatus(AgentPlusBookingManagerColumns.Carrier);
                                  }}
                                />
                              }
                              label="Carrier"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={B2cSettings?.allowedBookingManagerCouponColumns !== undefined ? B2cSettings?.allowedBookingManagerCouponColumns.includes(AgentPlusBookingManagerColumns.Exchangeable):false}
                                  onChange={() => {
                                    OnToggleColumnStatus(AgentPlusBookingManagerColumns.Exchangeable);
                                  }}
                                />
                              }
                              label="Exchangeable"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={B2cSettings?.allowedBookingManagerCouponColumns !== undefined ? B2cSettings?.allowedBookingManagerCouponColumns.includes(AgentPlusBookingManagerColumns.Extensions):false}
                                  onChange={() => {
                                    OnToggleColumnStatus(AgentPlusBookingManagerColumns.Extensions);
                                  }}
                                />
                              }
                              label="Extensions"
                            />
                          </Grid>    
                        </Grid>
                        <Grid item xs={6}>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={B2cSettings?.allowedBookingManagerCouponColumns !== undefined ? B2cSettings?.allowedBookingManagerCouponColumns.includes(AgentPlusBookingManagerColumns.LastEMDDate):false}
                                  onChange={() => {
                                    OnToggleColumnStatus(AgentPlusBookingManagerColumns.LastEMDDate);
                                  }}
                                />
                              }
                              label="Last EMD Date"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={B2cSettings?.allowedBookingManagerCouponColumns !== undefined ? B2cSettings?.allowedBookingManagerCouponColumns.includes(AgentPlusBookingManagerColumns.NVB):false}
                                  onChange={() => {
                                    OnToggleColumnStatus(AgentPlusBookingManagerColumns.NVB);
                                  }}
                                />
                              }
                              label="NVB"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={B2cSettings?.allowedBookingManagerCouponColumns !== undefined ? B2cSettings?.allowedBookingManagerCouponColumns.includes(AgentPlusBookingManagerColumns.NVA):false}
                                  onChange={() => {
                                    OnToggleColumnStatus(AgentPlusBookingManagerColumns.NVA);
                                  }}
                                />
                              }
                              label="NVA"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={B2cSettings?.allowedBookingManagerCouponColumns !== undefined ? B2cSettings?.allowedBookingManagerCouponColumns.includes(AgentPlusBookingManagerColumns.Refundable):false}
                                  onChange={() => {
                                    OnToggleColumnStatus(AgentPlusBookingManagerColumns.Refundable);
                                  }}
                                />
                              }
                              label="Refundable"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={B2cSettings?.allowedBookingManagerCouponColumns !== undefined ? B2cSettings?.allowedBookingManagerCouponColumns.includes(AgentPlusBookingManagerColumns.Segment):false}
                                  onChange={() => {
                                    OnToggleColumnStatus(AgentPlusBookingManagerColumns.Segment);
                                  }}
                                />
                              }
                              label="Segment"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={B2cSettings?.allowedBookingManagerCouponColumns !== undefined ? B2cSettings?.allowedBookingManagerCouponColumns.includes(AgentPlusBookingManagerColumns.PassengerName):false}
                                  onChange={() => {
                                    OnToggleColumnStatus(AgentPlusBookingManagerColumns.PassengerName);
                                  }}
                                />
                              }
                              label="Passenger Name"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={B2cSettings?.allowedBookingManagerCouponColumns !== undefined ? B2cSettings?.allowedBookingManagerCouponColumns.includes(AgentPlusBookingManagerColumns.ServiceCode):false}
                                  onChange={() => {
                                    OnToggleColumnStatus(AgentPlusBookingManagerColumns.ServiceCode);
                                  }}
                                />
                              }
                              label="Service Code"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={B2cSettings?.allowedBookingManagerCouponColumns !== undefined ? B2cSettings?.allowedBookingManagerCouponColumns.includes(AgentPlusBookingManagerColumns.ServiceText):false}
                                  onChange={() => {
                                    OnToggleColumnStatus(AgentPlusBookingManagerColumns.ServiceText);
                                  }}
                                />
                              }
                              label="Service Text"
                            />
                          </Grid>    
                        </Grid>
                        
                        
                      </Grid>

                    </> : null}
                <Divider />
                {
                  getLastPathSegment() == "generalB2C" ? <Grid style={{ margin: "25px 5px" }} item xs={12}>
                    <Card>
                      <CardContent>
                        <h2 className="font-monospace">
                          General display settings for B2C
                        </h2>
                        <Grid container>
                          <Grid item xs={3}>
                            <Box>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  Show B2C hotel tab{" "}
                                  <HelpTextButton
                                    HelpText={
                                      HelpTexts.DisplaySettingsShowHotelTabHelpText
                                    }
                                  />
                                </FormLabel>
                                <RadioGroup
                                  aria-label="position"
                                  name="position"
                                  value={B2cSettings?.HotelTab}
                                  onChange={(e) =>
                                    setB2cSettings({
                                      ...B2cSettings,
                                      HotelTab: e.target.value,
                                    })
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    value={ShowHotelTabB2C.Admin}
                                    control={<Radio color="primary" />}
                                    label="Admin"
                                    labelPlacement="bottom"
                                  />
                                  <FormControlLabel
                                    value={ShowHotelTabB2C.All}
                                    control={<Radio color="primary" />}
                                    label="All"
                                    labelPlacement="bottom"
                                  />
                                  <FormControlLabel
                                    value={ShowHotelTabB2C.None}
                                    control={<Radio color="primary" />}
                                    label="None"
                                    labelPlacement="bottom"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item xs={3}>
                            <Box>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  Show airports in search{" "}
                                  <HelpTextButton
                                    HelpText={
                                      HelpTexts.DisplaySettingsB2CAirportSearch
                                    }
                                  />
                                </FormLabel>
                                <RadioGroup
                                  aria-label="position"
                                  name="position"
                                  value={
                                    B2cSettings?.AirPortSearchType != null
                                      ? B2cSettings?.AirPortSearchType
                                      : "All"
                                  }
                                  onChange={(e) =>
                                    setB2cSettings({
                                      ...B2cSettings,
                                      AirPortSearchType: e.target.value,
                                    })
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    value={AirPortSearchType.All}
                                    control={<Radio color="primary" />}
                                    label="All"
                                    labelPlacement="bottom"
                                  />
                                  <FormControlLabel
                                    value={AirPortSearchType.Most}
                                    control={<Radio color="primary" />}
                                    label="Most"
                                    labelPlacement="bottom"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item xs={3}>
                            <Box>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  Show extra services{" "}
                                  <HelpTextButton
                                    HelpText={
                                      HelpTexts.DisplaySettingsB2CExtraService
                                    }
                                  />
                                </FormLabel>
                                <RadioGroup
                                  aria-label="position"
                                  name="position"
                                  value={
                                    B2cSettings?.ShowExtraAncillariesTabB2C !=
                                      null
                                      ? B2cSettings?.ShowExtraAncillariesTabB2C
                                      : "On"
                                  }
                                  onChange={(e) =>
                                    setB2cSettings({
                                      ...B2cSettings,
                                      ShowExtraAncillariesTabB2C: e.target.value,
                                    })
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    value={ShowExtraAncillariesTabB2C.On}
                                    control={<Radio color="primary" />}
                                    label="On"
                                    labelPlacement="bottom"
                                  />
                                  <FormControlLabel
                                    value={ShowExtraAncillariesTabB2C.Off}
                                    control={<Radio color="primary" />}
                                    label="Off"
                                    labelPlacement="bottom"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item xs={3}>
                            <Box>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  Preferred language{" "}
                                  <HelpTextButton
                                    HelpText={
                                      HelpTexts.DisplaySettingsB2CPreferredLanguage
                                    }
                                  />
                                </FormLabel>
                                <RadioGroup
                                  aria-label="position"
                                  name="position"
                                  value={
                                    B2cSettings?.ShowLanguageDecimalTabB2C != null
                                      ? B2cSettings?.ShowLanguageDecimalTabB2C
                                      : "English"
                                  }
                                  onChange={(e) =>
                                    setB2cSettings({
                                      ...B2cSettings,
                                      ShowLanguageDecimalTabB2C: e.target.value,
                                    })
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    value={ShowLanguageDecimalTabB2C.English}
                                    control={<Radio color="primary" />}
                                    label="English"
                                    labelPlacement="bottom"
                                  />
                                  <FormControlLabel
                                    value={ShowLanguageDecimalTabB2C.German}
                                    control={<Radio color="primary" />}
                                    label="German"
                                    labelPlacement="bottom"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </Grid>
                        </Grid>
                        <br />
                        <Grid container>
                          <Grid item xs={3}>
                            <Box>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  Date input{" "}
                                  <HelpTextButton
                                    HelpText={
                                      HelpTexts.DisplaySettingsB2CPreferredLanguage
                                    }
                                  />
                                </FormLabel>
                                <RadioGroup
                                  aria-label="position"
                                  name="position"
                                  value={
                                    B2cSettings?.ShowDateInput != null
                                      ? B2cSettings?.ShowDateInput
                                      : "Left"
                                  }
                                  onChange={(e) =>
                                    setB2cSettings({
                                      ...B2cSettings,
                                      ShowDateInput: e.target.value,
                                    })
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    value={ShowDateInput.Left}
                                    control={<Radio color="primary" />}
                                    label="Left"
                                    labelPlacement="bottom"
                                  />
                                  <FormControlLabel
                                    value={ShowDateInput.Right}
                                    control={<Radio color="primary" />}
                                    label="Right"
                                    labelPlacement="bottom"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item xs={3}>
                            <Box>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  Show default filter{" "}
                                  <HelpTextButton
                                    HelpText={
                                      HelpTexts.DisplaySettingsB2CPreferredLanguage
                                    }
                                  />
                                </FormLabel>
                                <RadioGroup
                                  aria-label="position"
                                  name="position"
                                  value={
                                    B2cSettings?.ShowDefaultFilter != null
                                      ? B2cSettings?.ShowDefaultFilter
                                      : ShowDefaultFilter.On
                                  }
                                  onChange={(e) =>
                                    setB2cSettings({
                                      ...B2cSettings,
                                      ShowDefaultFilter: e.target.value,
                                    })
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    value={ShowDefaultFilter.On}
                                    control={<Radio color="primary" />}
                                    label="On"
                                    labelPlacement="bottom"
                                  />
                                  <FormControlLabel
                                    value={ShowDefaultFilter.Off}
                                    control={<Radio color="primary" />}
                                    label="Off"
                                    labelPlacement="bottom"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item xs={3}>
                            <Box>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  Show Assistance{" "}
                                  <HelpTextButton
                                    HelpText={
                                      HelpTexts.DisplaySettingsB2CAssistance
                                    }
                                  />
                                </FormLabel>
                                <RadioGroup
                                  aria-label="position"
                                  name="position"
                                  value={
                                    B2cSettings?.showAssistance != null
                                      ? B2cSettings?.showAssistance
                                      : ShowDefaultAssistance.On
                                  }
                                  onChange={(e) =>
                                    setB2cSettings({
                                      ...B2cSettings,
                                      showAssistance: e.target.value,
                                    })
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    value={ShowDefaultAssistance.On}
                                    control={<Radio color="primary" />}
                                    label="On"
                                    labelPlacement="bottom"
                                  />
                                  <FormControlLabel
                                    value={ShowDefaultAssistance.Off}
                                    control={<Radio color="primary" />}
                                    label="Off"
                                    labelPlacement="bottom"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item xs={3}>
                            <Box>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  Show modal background
                                  <HelpTextButton
                                    HelpText={
                                      HelpTexts.DisplaySettingsB2CModalColorB
                                    }
                                  />
                                </FormLabel>
                                <RadioGroup
                                  aria-label="position"
                                  name="position"
                                  value={
                                    B2cSettings?.ShowBackgroundModalColor != null
                                      ? B2cSettings?.ShowBackgroundModalColor
                                      : ShowBackgroundModalColor.On
                                  }
                                  onChange={(e) =>
                                    setB2cSettings({
                                      ...B2cSettings,
                                      ShowBackgroundModalColor: e.target.value,
                                    })
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    value={ShowBackgroundModalColor.On}
                                    control={<Radio color="primary" />}
                                    label="On"
                                    labelPlacement="bottom"
                                  />
                                  <FormControlLabel
                                    value={ShowBackgroundModalColor.Off}
                                    control={<Radio color="primary" />}
                                    label="Off"
                                    labelPlacement="bottom"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container style={{ marginTop: "25px", justifyContent: 'space-between' }}>
                          <Grid item xs={3}
                            // style={{ margin: "10px", minWidth: "200px" }}
                            className="FieldcontainerCss">
                            <FormLabel style={{ paddingBottom: "8px" }} component="legend">
                              Terms & conditions link
                              <HelpTextButton HelpText={HelpTexts.DisplaySettingsB2CLinkContainer} />
                            </FormLabel>
                            <Box mb={2}>
                              <FormControl component="fieldset">
                                <Input
                                  className="textInputResize"
                                  value={B2cSettings?.TermsConditionsLink}
                                  onChange={(val) => {
                                    setB2cSettings({
                                      ...B2cSettings,
                                      TermsConditionsLink: val.target.value
                                    })
                                  }}
                                />
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item xs={3}>
                            <Box>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  Show Price{" "}
                                  <HelpTextButton
                                    HelpText={
                                      HelpTexts.DisplaySettingsB2CBrandName
                                    }
                                  />
                                </FormLabel>
                                <RadioGroup
                                  aria-label="position"
                                  name="position"
                                  value={
                                    B2cSettings?.priceOption != null
                                      ? B2cSettings?.priceOption
                                      : showPriceOption.pricePerPerson
                                  }
                                  onChange={(e) =>
                                    setB2cSettings({
                                      ...B2cSettings,
                                      priceOption: e.target.value,
                                    })
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    value={showPriceOption.pricePerPerson}
                                    control={<Radio color="primary" />}
                                    label="Price per person"
                                    labelPlacement="bottom"
                                  />
                                  <FormControlLabel
                                    value={showPriceOption.totalPrice}
                                    control={<Radio color="primary" />}
                                    label="Total price"
                                    labelPlacement="bottom"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item xs={3}>
                            <Box>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  Show brand name{" "}
                                  <HelpTextButton
                                    HelpText={
                                      HelpTexts.DisplaySettingsB2CBrandName
                                    }
                                  />
                                </FormLabel>
                                <RadioGroup
                                  aria-label="position"
                                  name="position"
                                  value={
                                    B2cSettings?.showBrandName != null
                                      ? B2cSettings?.showBrandName
                                      : ShowDefaultBrandName.On
                                  }
                                  onChange={(e) =>
                                    setB2cSettings({
                                      ...B2cSettings,
                                      showBrandName: e.target.value,
                                    })
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    value={ShowDefaultBrandName.On}
                                    control={<Radio color="primary" />}
                                    label="On"
                                    labelPlacement="bottom"
                                  />
                                  <FormControlLabel
                                    value={ShowDefaultBrandName.Off}
                                    control={<Radio color="primary" />}
                                    label="Off"
                                    labelPlacement="bottom"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </Grid>

                        </Grid>
                        <Grid container style={{ marginTop: "25px", gap: '24px' }}>
                          <Grid item xs={4}
                            className="FieldcontainerCss mw-200"
                          >
                            <FormLabel
                              style={{ paddingBottom: "8px" }}
                              component="legend"
                            >
                              Style
                              <HelpTextButton HelpText={HelpTexts.DisplaySettingsB2CStyle} />
                            </FormLabel>
                            <Box mb={2}>
                              <FormControl component="fieldset" style={{ minWidth: '100%' }}>
                                <TextareaAutosize
                                  className="textInputResize"
                                  minRows={8}
                                  value={
                                    B2cSettings?.Style
                                  }
                                  onChange={(val) => {
                                    setB2cSettings({
                                      ...B2cSettings,
                                      Style: val.target.value,
                                    })
                                  }}
                                />
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item xs={4}
                            // style={{ margin: "10px", minWidth: "200px" }}
                            className="FieldcontainerCss">
                            <FormLabel style={{ paddingBottom: "8px" }} component="legend">
                              Confirmation text
                              <HelpTextButton HelpText={HelpTexts.DisplaySettingsB2CConfirmationText} />
                            </FormLabel>
                            <Box mb={2}>
                              <FormControl component="fieldset">
                                <TextareaAutosize
                                  className="textInputResize"
                                  minRows={8}
                                  value={B2cSettings?.ConfirmationText}
                                  onChange={(val) => {
                                    setB2cSettings({
                                      ...B2cSettings,
                                      ConfirmationText: val.target.value
                                    })
                                  }}
                                />
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item xs={3}>
                            <Box>
                              <FormLabel
                                style={{ paddingBottom: "8px" }}
                                component="legend"
                              >
                                Show cabin classes
                                <HelpTextButton
                                  HelpText={HelpTexts.CabinClassViewAndSelection}
                                />
                              </FormLabel>
                              <FormControl component="fieldset">
                                <FormLabel
                                  className="showCabinCss"
                                  component="legend"
                                >
                                  Show cabin
                                </FormLabel>
                                <FormGroup>
                                  <FormControlLabel
                                    className="flexChild labelContainer"
                                    label="All"
                                    control={
                                      <Checkbox
                                        checked={
                                          B2cSettings?.CabinClass?.Economy
                                            ?.Checked &&
                                          B2cSettings?.CabinClass?.Premium
                                            ?.Checked &&
                                          B2cSettings?.CabinClass?.Business
                                            ?.Checked &&
                                          B2cSettings?.CabinClass?.First?.Checked
                                        }
                                        indeterminate={
                                          (B2cSettings?.CabinClass?.Economy
                                            ?.Checked ||
                                            B2cSettings?.CabinClass?.Premium
                                              ?.Checked ||
                                            B2cSettings?.CabinClass?.Business
                                              ?.Checked ||
                                            B2cSettings?.CabinClass?.First
                                              ?.Checked) &&
                                          !(
                                            B2cSettings?.CabinClass?.Economy
                                              ?.Checked &&
                                            B2cSettings?.CabinClass?.Premium
                                              ?.Checked &&
                                            B2cSettings?.CabinClass?.Business
                                              ?.Checked &&
                                            B2cSettings?.CabinClass?.First
                                              ?.Checked
                                          )
                                        }
                                        onChange={(e) => {
                                          const isChecked = e.target.checked;
                                          setB2cSettings((prevState) => ({
                                            ...prevState,
                                            CabinClass: {
                                              ...(prevState.CabinClass || {}), // Ensure CabinClass is defined
                                              Economy: {
                                                ...(prevState.CabinClass
                                                  ?.Economy || {}), // Ensure Economy is defined
                                                Checked: isChecked,
                                              },
                                              Premium: {
                                                ...(prevState.CabinClass
                                                  ?.Premium || {}), // Ensure Premium is defined
                                                Checked: isChecked,
                                              },
                                              Business: {
                                                ...(prevState.CabinClass
                                                  ?.Business || {}), // Ensure Business is defined
                                                Checked: isChecked,
                                              },
                                              First: {
                                                ...(prevState.CabinClass?.First ||
                                                  {}), // Ensure First is defined
                                                Checked: isChecked,
                                              },
                                            },
                                          }));
                                        }}
                                      />
                                    }
                                  />

                                  <FormControlLabel
                                    className="flexChild labelContainer"
                                    control={
                                      <Checkbox
                                        checked={
                                          B2cSettings?.CabinClass?.Economy
                                            ?.Checked
                                        } // Optional chaining used to prevent accessing properties on undefined/null
                                        onChange={(e) =>
                                          setB2cSettings((prevState) => ({
                                            ...prevState,
                                            CabinClass: {
                                              ...prevState.CabinClass,
                                              Economy: {
                                                // Ensure that Economy property is defined
                                                ...prevState.CabinClass?.Economy,
                                                Checked: e.target.checked,
                                              },
                                            },
                                          }))
                                        }
                                        value={cabinClasses?.Economy}
                                      />
                                    }
                                    label="Economy"
                                  />
                                  <FormControlLabel
                                    className="flexChild labelContainer"
                                    control={
                                      <Checkbox
                                        checked={
                                          B2cSettings?.CabinClass?.Premium
                                            ?.Checked
                                        }
                                        onChange={(e) =>
                                          setB2cSettings((prevState) => ({
                                            ...prevState,
                                            CabinClass: {
                                              ...prevState.CabinClass,
                                              Premium: {
                                                // Ensure that Economy property is defined
                                                ...prevState.CabinClass?.Premium,
                                                Checked: e.target.checked,
                                              },
                                            },
                                          }))
                                        }
                                        value={cabinClasses?.Premium}
                                      />
                                    }
                                    label="Premium"
                                  />
                                  <FormControlLabel
                                    className="flexChild labelContainer"
                                    control={
                                      <Checkbox
                                        checked={
                                          B2cSettings?.CabinClass?.Business
                                            ?.Checked
                                        }
                                        onChange={(e) =>
                                          setB2cSettings((prevState) => ({
                                            ...prevState,
                                            CabinClass: {
                                              ...prevState.CabinClass,
                                              Business: {
                                                // Ensure that Economy property is defined
                                                ...prevState.CabinClass?.Business,
                                                Checked: e.target.checked,
                                              },
                                            },
                                          }))
                                        }
                                        value={cabinClasses?.Business}
                                      />
                                    }
                                    label="Business"
                                  />
                                  <FormControlLabel
                                    className="flexChild labelContainer"
                                    control={
                                      <Checkbox
                                        checked={
                                          B2cSettings?.CabinClass?.First?.Checked
                                        }
                                        onChange={(e) =>
                                          setB2cSettings((prevState) => ({
                                            ...prevState,
                                            CabinClass: {
                                              ...prevState.CabinClass,
                                              First: {
                                                // Ensure that Economy property is defined
                                                ...prevState.CabinClass?.First,
                                                Checked: e.target.checked,
                                              },
                                            },
                                          }))
                                        }
                                        value={cabinClasses?.First}
                                      />
                                    }
                                    label="First"
                                  />
                                </FormGroup>
                              </FormControl>
                              <FormControl
                                component="fieldset"
                                className="preSelectCont"
                              >
                                <FormLabel component="legend">
                                  Preselect
                                </FormLabel>
                                <FormGroup>
                                  <FormControlLabel
                                    className="rightCheckBoxAlignmentCss"
                                    label=""
                                    control={
                                      <Checkbox
                                        checked={
                                          B2cSettings?.CabinClass?.Economy
                                            ?.preSelect &&
                                          B2cSettings?.CabinClass?.Premium
                                            ?.preSelect &&
                                          B2cSettings?.CabinClass?.Business
                                            ?.preSelect &&
                                          B2cSettings?.CabinClass?.First
                                            ?.preSelect
                                        }
                                        indeterminate={
                                          (B2cSettings?.CabinClass?.Economy
                                            ?.preSelect ||
                                            B2cSettings?.CabinClass?.Premium
                                              ?.preSelect ||
                                            B2cSettings?.CabinClass?.Business
                                              ?.preSelect ||
                                            B2cSettings?.CabinClass?.First
                                              ?.preSelect) &&
                                          !(
                                            B2cSettings?.CabinClass?.Economy
                                              ?.preSelect &&
                                            B2cSettings?.CabinClass?.Premium
                                              ?.preSelect &&
                                            B2cSettings?.CabinClass?.Business
                                              ?.preSelect &&
                                            B2cSettings?.CabinClass?.First
                                              ?.preSelect
                                          )
                                        }
                                        onChange={(e) => {
                                          const isChecked = e.target.checked;
                                          setB2cSettings((prevState) => ({
                                            ...prevState,
                                            CabinClass: {
                                              Economy: {
                                                ...(prevState.CabinClass
                                                  ?.Economy || {}),
                                                preSelect: isChecked,
                                              },
                                              Premium: {
                                                ...(prevState.CabinClass
                                                  ?.Premium || {}),
                                                preSelect: isChecked,
                                              },
                                              Business: {
                                                ...(prevState.CabinClass
                                                  ?.Business || {}),
                                                preSelect: isChecked,
                                              },
                                              First: {
                                                ...(prevState.CabinClass?.First ||
                                                  {}),
                                                preSelect: isChecked,
                                              },
                                            },
                                          }));
                                        }}
                                      />
                                    }
                                  />

                                  <FormControlLabel
                                    className=" rightCheckBoxAlignmentCss"
                                    control={
                                      <Checkbox
                                        checked={
                                          B2cSettings?.CabinClass?.Economy
                                            ?.preSelect
                                        }
                                        onChange={(e) =>
                                          setB2cSettings((prevState) => ({
                                            ...prevState,
                                            CabinClass: {
                                              ...prevState.CabinClass,
                                              Economy: {
                                                // Ensure that Economy property is defined
                                                ...prevState.CabinClass?.Economy,
                                                preSelect: e.target.checked,
                                              },
                                            },
                                          }))
                                        }
                                        value={cabinClasses.Economy}
                                      />
                                    }
                                    label=""
                                  />
                                  <FormControlLabel
                                    className="rightCheckBoxAlignmentCss "
                                    control={
                                      <Checkbox
                                        checked={
                                          B2cSettings?.CabinClass?.Premium
                                            ?.preSelect
                                        }
                                        onChange={(e) =>
                                          setB2cSettings((prevState) => ({
                                            ...prevState,
                                            CabinClass: {
                                              ...prevState.CabinClass,
                                              Premium: {
                                                // Ensure that Economy property is defined
                                                ...prevState.CabinClass?.Premium,
                                                preSelect: e.target.checked,
                                              },
                                            },
                                          }))
                                        }
                                        value={cabinClasses.Premium}
                                      />
                                    }
                                    label=""
                                  />
                                  <FormControlLabel
                                    className=" rightCheckBoxAlignmentCss"
                                    control={
                                      <Checkbox
                                        checked={
                                          B2cSettings?.CabinClass?.Business
                                            ?.preSelect
                                        }
                                        onChange={(e) =>
                                          setB2cSettings((prevState) => ({
                                            ...prevState,
                                            CabinClass: {
                                              ...prevState.CabinClass,
                                              Business: {
                                                // Ensure that Economy property is defined
                                                ...prevState.CabinClass?.Business,
                                                preSelect: e.target.checked,
                                              },
                                            },
                                          }))
                                        }
                                        value={cabinClasses.Business}
                                      />
                                    }
                                    label=""
                                  />
                                  <FormControlLabel
                                    className=" rightCheckBoxAlignmentCss"
                                    control={
                                      <Checkbox
                                        checked={
                                          B2cSettings?.CabinClass?.First
                                            ?.preSelect
                                        }
                                        onChange={(e) =>
                                          setB2cSettings((prevState) => ({
                                            ...prevState,
                                            CabinClass: {
                                              ...prevState.CabinClass,
                                              First: {
                                                // Ensure that Economy property is defined
                                                ...prevState.CabinClass?.First,
                                                preSelect: e.target.checked,
                                              },
                                            },
                                          }))
                                        }
                                        value={cabinClasses.First}
                                      />
                                    }
                                    label=""
                                  />
                                </FormGroup>
                              </FormControl>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={3} style={{ marginTop: "25px", gap: '24px' }}>
                            <Box>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  Show passenger title{" "}
                                  <HelpTextButton
                                    HelpText={
                                      HelpTexts.DisplaySettingsB2CPassengerTitle
                                    }
                                  />
                                </FormLabel>
                                <RadioGroup
                                  aria-label="position"
                                  name="position"
                                  value={
                                    B2cSettings?.showPassengerTitleName != null
                                      ? B2cSettings?.showPassengerTitleName
                                      : ShowDefaultBrandName.On
                                  }
                                  onChange={(e) =>
                                    setB2cSettings({
                                      ...B2cSettings,
                                      showPassengerTitleName: e.target.value,
                                    })
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    value={ShowDefaultBrandName.On}
                                    control={<Radio color="primary" />}
                                    label="On"
                                    labelPlacement="bottom"
                                  />
                                  <FormControlLabel
                                    value={ShowDefaultBrandName.Off}
                                    control={<Radio color="primary" />}
                                    label="Off"
                                    labelPlacement="bottom"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </Grid>


                          <Grid item xs={3}>
                            <Box>
                              <FormLabel style={{ paddingBottom: "8px" }} component="legend">
                                Allowed language
                                <HelpTextButton HelpText={HelpTexts.LanguageViewAndSelection} />
                              </FormLabel>
                              <FormControl component="fieldset">
                                <FormLabel className="showCabinCss" component="legend"></FormLabel>
                                <FormGroup
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(3, 1fr)", // 3 columns
                                    gap: "10px", // spacing between columns
                                  }}
                                >
                                  {/* "All" Checkbox */}
                                  <FormControlLabel
                                    className="flexChild labelContainer"
                                    label="All"
                                    control={
                                      <Checkbox
                                        checked={Object.values(B2cSettings?.AllowedLanguage || {}).every((lang) => lang.Checked)}
                                        indeterminate={
                                          Object.values(B2cSettings?.AllowedLanguage || {}).some((lang) => lang.Checked) &&
                                          !Object.values(B2cSettings?.AllowedLanguage || {}).every((lang) => lang.Checked)
                                        }
                                        onChange={(e) => {
                                          const isChecked = e.target.checked;
                                          setB2cSettings((prevState) => ({
                                            ...prevState,
                                            AllowedLanguage: Object.keys(prevState.AllowedLanguage || {}).reduce(
                                              (acc, code) => ({
                                                ...acc,
                                                [code]: {
                                                  ...(prevState.AllowedLanguage?.[code] || {}),
                                                  Checked: isChecked,
                                                },
                                              }),
                                              {}
                                            ),
                                          }));
                                        }}
                                      />
                                    }
                                  />
                                  {/* Individual language checkboxes */}
                                  {Object.entries(AllowedLanguageCode).map(([code, lang]) => (
                                    <FormControlLabel
                                      className="flexChild labelContainer"
                                      control={
                                        <Checkbox
                                          checked={B2cSettings?.AllowedLanguage?.[code]?.Checked}
                                          onChange={(e) =>
                                            setB2cSettings((prevState) => ({
                                              ...prevState,
                                              AllowedLanguage: {
                                                ...prevState.AllowedLanguage,
                                                [code]: {
                                                  ...prevState.AllowedLanguage?.[code],
                                                  Checked: e.target.checked,
                                                },
                                              },
                                            }))
                                          }
                                          value={code}
                                        />
                                      }
                                      label={code}
                                      key={code}
                                    />
                                  ))}
                                </FormGroup>
                              </FormControl>
                            </Box>
                          </Grid>


                          <Grid item xs={3} style={{ marginTop: "25px", gap: '24px' }}>
                            <Box>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  Show filter position{" "}
                                  <HelpTextButton
                                    HelpText={
                                      HelpTexts.DisplaySettingsB2CFilterPosition
                                    }
                                  />
                                </FormLabel>
                                <RadioGroup
                                  aria-label="position"
                                  name="position"
                                  value={
                                    B2cSettings?.showFilterPosition != null
                                      ? B2cSettings?.showFilterPosition
                                      : ShowDefaultFilterPosition.Right
                                  }
                                  onChange={(e) =>
                                    setB2cSettings({
                                      ...B2cSettings,
                                      showFilterPosition: e.target.value,
                                    })
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    value={ShowDefaultFilterPosition.Left}
                                    control={<Radio color="primary" />}
                                    label="Left"
                                    labelPlacement="bottom"
                                  />
                                  <FormControlLabel
                                    value={ShowDefaultFilterPosition.Right}
                                    control={<Radio color="primary" />}
                                    label="Right"
                                    labelPlacement="bottom"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </Grid>

                          <Grid item xs={3}>
                            <Box>
                              {/* filter options sorting */}
                              <Grid
                                style={{ margin: "10px", minWidth: "400px" }}
                                className=""
                                item
                                xs={4}
                              >
                                <Box mb={2} className="filterOrderContainer">
                                  <FormLabel
                                    style={{ paddingBottom: "8px" }}
                                    component="legend"
                                  >
                                    Filters
                                    <HelpTextButton
                                      HelpText={HelpTexts.FiltersOrderHelperTextB2C}
                                    />
                                  </FormLabel>
                                  <FormControl component="fieldset">
                                    <DragDropContext onDragEnd={handleDragEndB2C}>
                                      <Droppable droppableId="filter-list">
                                        {(provided) => (
                                          <FormGroup {...provided.droppableProps} ref={provided.innerRef}>
                                            {filtersB2C?.map((filter, index) => (
                                              <Draggable key={filter?.label} draggableId={filter?.label} index={index}>
                                                {(provided) => (
                                                  <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                  >
                                                    <FormControlLabel
                                                      className="flexChild labelContainer"
                                                      control={
                                                        <Checkbox
                                                          checked={filter?.checked}
                                                          onChange={() => handleChangeFilterOptionB2C(filter?.label)}
                                                        />
                                                      }
                                                      label={filter.label}
                                                    />
                                                  </div>
                                                )}
                                              </Draggable>
                                            ))}
                                            {provided.placeholder}
                                          </FormGroup>
                                        )}
                                      </Droppable>
                                    </DragDropContext>
                                  </FormControl>
                                </Box>
                              </Grid>
                            </Box>
                          </Grid>

                        </Grid>
                        <hr
                          style={{ width: "50%", margin: "10px 0px" }}
                          className="MuiDivider-root"
                        />
                        <Grid container>
                          <Grid item xs={3}>
                            <Box>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  Show Upsell{" "}
                                  <HelpTextButton
                                    HelpText={
                                      HelpTexts.DisplaySettingsB2CUpsell
                                    }
                                  />
                                </FormLabel>
                                <RadioGroup
                                  aria-label="position"
                                  name="position"
                                  value={
                                    B2cSettings?.showUpsell != null
                                      ? B2cSettings?.showUpsell
                                      : ShowDefaultUpsell.On
                                  }
                                  onChange={(e) =>
                                    setB2cSettings({
                                      ...B2cSettings,
                                      showUpsell: e.target.value,
                                    })
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    value={ShowDefaultUpsell.On}
                                    control={<Radio color="primary" />}
                                    label="On"
                                    labelPlacement="bottom"
                                  />
                                  <FormControlLabel
                                    value={ShowDefaultUpsell.Off}
                                    control={<Radio color="primary" />}
                                    label="Off"
                                    labelPlacement="bottom"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item xs={3}>
                        <Box>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              Show fare header {" "}
                              <HelpTextButton
                                HelpText={
                                  HelpTexts.DisplaySettingsB2CFareHeader
                                }
                              />
                            </FormLabel>
                            <RadioGroup
                              aria-label="position"
                              name="position"
                              value={
                                B2cSettings?.showFareHeaderB2C != null
                                  ? B2cSettings?.showFareHeaderB2C
                                  : ShowDefaultFareHeaderB2C.On
                              }
                              onChange={(e) =>
                                setB2cSettings({
                                  ...B2cSettings,
                                  showFareHeaderB2C: e.target.value,
                                })
                              }
                              row
                            >
                              <FormControlLabel
                                value={ShowDefaultFareHeaderB2C.On}
                                control={<Radio color="primary" />}
                                label="On"
                                labelPlacement="bottom"
                              />
                              <FormControlLabel
                                value={ShowDefaultFareHeaderB2C.Off}
                                control={<Radio color="primary" />}
                                label="Off"
                                labelPlacement="bottom"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid> : null
                }

              </Grid>
            ) : (
              <CreateSettingsButton
                OnClick={CreateSettings}
                Readonly={props.ReadOnly}
              />
            )}
          </CardContent>
        )}

        {loading && (
          <CardContent>
            <LinearProgress />
          </CardContent>
        )}

        {!ReadOnly && Settings && isSetting && (
          <SettingsActions
            OnDelete={DeleteSettings}
            OnReset={Reload}
            OnSave={OnUpdate}
          />
        )}
      </Card>
    </Box>
  );
};

export default GeneralDisplaySettings;
